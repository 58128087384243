import { Statistic, Typography, Button, Space, Popconfirm } from 'antd';
import React from 'react';
import QuestionComponent from './ComponentQuestion';
import './../../assets/css/assessment.css';

const QuizSection = ({
    quiz,
    computeDeadline,
    assessment,
    handleTimeCompleted,
    displayedIndex,
    loading,
    navigateQuestionItem,
    answers,
    isLastIndex,
    cancel,
    btnLoading,
    setAnswers,
    handleSubmit,
    quizComputeDeadline
}) => {
    const { Countdown } = Statistic;
    const { Title, Paragraph } = Typography;

    const handleNextClick = () => {
        localStorage.setItem(`Quiz-${quiz.id}`, JSON.stringify(answers));
        navigateQuestionItem(displayedIndex + 1);
    };

    const handleQuizNumberClick = (i) => {
        localStorage.setItem(`Quiz-${quiz.id}`, JSON.stringify(answers));
        navigateQuestionItem(i);
    };
    return (
        <React.Fragment>
            <div>
                <Title>{quiz?.title}</Title>
                {computeDeadline && (
                    <Countdown
                        title="Time remaining: "
                        value={parseInt(computeDeadline(quiz, assessment))}
                        onFinish={handleTimeCompleted}
                    />
                )}

                {quizComputeDeadline && (
                    <Countdown
                        title="Time remaining:"
                        value={parseInt(quizComputeDeadline)}
                        onFinish={handleTimeCompleted}
                    />
                )}
            </div>

            {/* <pre>{JSON.stringify(answers, 0, 2)}</pre> */}

            {!loading && (
                <Paragraph style={{ marginTop: '40px' }}>
                    <Space wrap>
                        {quiz?.questions.map((_q, i) => (
                            <Button
                                id={i}
                                key={i}
                                shape="circle"
                                className={i === displayedIndex ? 'indexSelected' : ''}
                                onClick={() => handleQuizNumberClick(i)}>
                                {i + 1}
                            </Button>
                        ))}
                    </Space>
                </Paragraph>
            )}
            <Paragraph>
                {answers && answers.length > 0 && (
                    <QuestionComponent
                        questions={quiz?.questions}
                        displayedIndex={displayedIndex}
                        answers={answers}
                        setAnswers={setAnswers}
                    />
                )}
            </Paragraph>
            <Paragraph style={{ marginTop: '40px' }}>
                {isLastIndex() ? (
                    <Popconfirm
                        title="Are you sure you want to end the assessment exam?"
                        description="Make sure you have answered all the questions before submitting because once submitted, empty answers will be marked as incorrect. End assessment now."
                        onConfirm={() => {
                            handleSubmit(answers);
                        }}
                        onCancel={cancel}
                        overlayStyle={{ width: 600 }}
                        okText="Confirm"
                        cancelText="Cancel">
                        <Button loading={btnLoading} type="primary">
                            End Exam
                        </Button>
                    </Popconfirm>
                ) : (
                    <Button type="primary" onClick={() => handleNextClick()}>
                        Next
                    </Button>
                )}
            </Paragraph>
        </React.Fragment>
    );
};

export default QuizSection;
