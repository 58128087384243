import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';
import { showErrorToast } from '../utils/ToastError';
import { showSuccessToast } from '../utils/ToastSuccess';
import { ToastContainer } from 'react-toastify';
import { API_GATEWAY_ENDPOINT } from '../queries/api/ApiGwOptions';
import { registerApiGatewayOptions } from './Register';
import { UIConfig, orgId } from '../config/ui-config';
import { validatePassword } from '../utils/validatePassword';
import axios from 'axios';
import PageLoader from '../components/layout/loaders/PageLoader';
import PageError from '../components/layout/PageError';

const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 }
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 }
};

const { Text } = Typography;

const verifyResetPasswordTokenQuery = `
mutation verifyResetPasswordToken($input: VerifyResetPasswordTokenInput!) {
    verifyResetPasswordToken(input: $input) {
        isTokenValid
        email
        name
    }
}
`;

const resetUserPasswordMutation = `
mutation resetUserPassword($input: ResetPasswordInput!) {
    resetUserPassword(input: $input) {
        success
        message
    }
}
`;

const ResetPassword = () => {
    const [isVerifying, setIsVerifying] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [verifyResetPasswordTokenResponse, setVerifyResetPasswordTokenResponse] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    const logoSrc = UIConfig[orgId]?.SignIn?.Logo;

    useEffect(() => {
        handleVerifyResetPasswordToken();
    }, []);

    const handleVerifyResetPasswordToken = async () => {
        const urlBody = searchParams?.get('key');

        const params = {
            query: verifyResetPasswordTokenQuery,
            variables: { input: { urlBody: urlBody } }
        };

        const response = await axios.post(`${API_GATEWAY_ENDPOINT}/auth`, params, registerApiGatewayOptions);
        setVerifyResetPasswordTokenResponse(response?.data?.verifyResetPasswordToken);
        setIsVerifying(false);
    };

    const handleResetPassword = async (values) => {
        setIsLoading(true);
        values.orgId = orgId;
        values.email = verifyResetPasswordTokenResponse.email;
        delete values.confirmPassword;

        const params = {
            query: resetUserPasswordMutation,
            variables: { input: values }
        };

        try {
            const res = await axios.post(`${API_GATEWAY_ENDPOINT}/auth`, params, registerApiGatewayOptions);
            setResponse(res.data);

            if (res.data.resetUserPassword.success) {
                showSuccessToast({ message: `${res.data.resetUserPassword.message}. Redirecting to login...` });
            } else showErrorToast({ message: res.data.resetUserPassword.message });

            setIsLoading(false);
            setTimeout(() => {
                navigate('/signin');
            }, 2000);
        } catch (error) {
            console.log(error);
        }
    };
    const onFinish = (values) => {
        handleResetPassword(values);
    };

    const onFinishFailed = (errorInfo) => {
        return errorInfo;
    };

    return (
        <>
            {isVerifying ? (
                <PageLoader />
            ) : (
                <>
                    {verifyResetPasswordTokenResponse?.isTokenValid ? (
                        <div className="login-page-container">
                            <img
                                src={logoSrc}
                                alt="Logo"
                                className={`${UIConfig[orgId]?.SignIn?.LogoStyles} cursor-pointer`}
                                onClick={() => {
                                    navigate('/signin');
                                }}
                            />
                            <Text type="secondary" className="mb-0 mt-4">
                                Hi {verifyResetPasswordTokenResponse.name}!
                            </Text>
                            <Text type="secondary" className="mb-8">
                                Enter your new password below:
                            </Text>

                            <Form
                                {...layout}
                                style={{ width: '345px' }}
                                name="login-form"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}>
                                <React.Fragment>
                                    <Form.Item
                                        className="mb-4"
                                        name="password"
                                        rules={[{ validator: validatePassword }]}>
                                        <Input.Password
                                            size="large"
                                            placeholder="New Password"
                                            className="input-centered-round"
                                            id="password"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirmPassword"
                                        id="confirm-password"
                                        dependencies={['password']}
                                        rules={[
                                            { required: true, message: 'Please confirm your password' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The passwords do not match'));
                                                }
                                            })
                                        ]}>
                                        <Input.Password
                                            size="large"
                                            placeholder="Confirm Password"
                                            className="input-centered-round"
                                        />
                                    </Form.Item>
                                </React.Fragment>

                                <Form.Item {...tailLayout}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        htmlType="submit"
                                        shape="round"
                                        className="signin"
                                        disabled={response?.resetUserPassword?.success}
                                        loading={isLoading}>
                                        Save Password
                                    </Button>
                                </Form.Item>
                            </Form>

                            <ToastContainer />
                        </div>
                    ) : (
                        <PageError message={'Invalid reset token.'} />
                    )}
                </>
            )}
        </>
    );
};

export default ResetPassword;
