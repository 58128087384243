import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography } from 'antd';
import { showErrorToast } from '../utils/ToastError';
import { showSuccessToast } from '../utils/ToastSuccess';
import { ToastContainer } from 'react-toastify';
import { API_GATEWAY_ENDPOINT } from '../queries/api/ApiGwOptions';
import { registerApiGatewayOptions } from './Register';
import { UIConfig, orgId } from '../config/ui-config';
import axios from 'axios';

const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 }
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 }
};

const { Text } = Typography;

const forgotPasswordMutation = `
mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
        message
        success
    }
}`;

const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);

    const navigate = useNavigate();

    const logoSrc = UIConfig[orgId]?.SignIn?.Logo;

    const handleConfirmEmail = async (values) => {
        setIsLoading(true);
        values.orgId = orgId;
        values.origin = process.env.REACT_APP_HOSTNAME;

        const params = {
            query: forgotPasswordMutation,
            variables: { input: values }
        };

        try {
            const res = await axios.post(`${API_GATEWAY_ENDPOINT}/auth`, params, registerApiGatewayOptions);
            setResponse(res.data);

            if (res.data.forgotPassword.success) {
                showSuccessToast({ message: res.data.forgotPassword.message });
            } else showErrorToast({ message: res.data.forgotPassword.message });

            setIsLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const onFinish = (values) => {
        handleConfirmEmail(values);
    };

    const onFinishFailed = (errorInfo) => {
        return errorInfo;
    };

    const descriptionText = response?.forgotPassword?.success
        ? 'An email containing a link to reset your password has been sent to your email address.'
        : "Enter your email address and we'll send you a link to reset your password.";

    return (
        <div className="login-page-container">
            <img
                src={logoSrc}
                alt="Logo"
                className={`${UIConfig[orgId]?.SignIn?.LogoStyles} cursor-pointer`}
                onClick={() => {
                    navigate('/signin');
                }}
            />
            <Text type="secondary" className="mb-8 mt-4 max-w-[360px] text-center ">
                {descriptionText}
            </Text>

            <Form
                {...layout}
                style={{ width: '345px' }}
                name="login-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <React.Fragment>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Email address is required.' },
                            { type: 'email', message: 'Please enter a valid email address.' }
                        ]}>
                        <Input
                            size="large"
                            placeholder="Email"
                            className="input-centered-round"
                            disabled={response?.forgotPassword?.success}
                        />
                    </Form.Item>
                </React.Fragment>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        size="large"
                        htmlType="submit"
                        shape="round"
                        className="signin"
                        disabled={response?.forgotPassword?.success}
                        loading={isLoading}>
                        Confirm Email
                    </Button>
                </Form.Item>
            </Form>
            <ToastContainer />
        </div>
    );
};

export default ForgotPassword;
