import { Typography } from 'antd';
import { UIConfig, orgId } from '../../../config/ui-config';
const { Text } = Typography;

const CourseLoader = ({ isLesson = true }) => {
    const loaderLogo = UIConfig[orgId]?.Loaders.ColoredLogo;
    return (
        <div className="flex h-[80vh] w-full flex-col items-center justify-center">
            <img
                src={loaderLogo}
                alt={'logo'}
                className="h-[100px]"
            />
            <Text className="mt-3 text-lg">{isLesson ? 'Loading lesson...' : 'Loading...'}</Text>
        </div>
    );
};

export default CourseLoader;
