import GcashTechAcademyBlueLogo from '../assets/logo.png';
import GcashTechAcademyWhiteLogo from '../assets/logo-white.png';
import GcashSigninLogo from '../assets/logo.png';

import CloudSchoolCloudLogo from '../assets/cloudschool-primary-logo.png';
import CloudSchoolWhiteTextLogo from '../assets/cloudschool-secondary-text-logo.png';
import CloudSchoolColoredCloudLogo from '../assets/cloudschool-round-logo.png';
import CloudSchoolSigninLogo from '../assets/cloudschool-text-logo.png';

import ApperCorpLogo from '../assets/apper/apper.png';
import ApperCorpColoredLogo from '../assets/apper/apper-colored.png';

export const orgId = process.env.REACT_APP_ORG_ID;

export const UIConfig = {
    'CLDSCHL-2024': {
        PrimaryColor: '#1E90FF',
        SecondaryColor: '#1C86EE',
        Headers: {
            WhiteLogo: CloudSchoolWhiteTextLogo,
            WhiteLogoStyles: 'h-[20px] mr-8'
        },
        Loaders: {
            WhiteLogo: CloudSchoolCloudLogo,
            WhiteLogoStyles: 'h-[50px]',
            ColoredLogo: CloudSchoolColoredCloudLogo
        },
        SignIn: {
            Logo: CloudSchoolSigninLogo,
            LogoStyles: 'h-[40px]'
        },
        RegisterUi: {
            Logo: CloudSchoolColoredCloudLogo
        }
    },
    'FECP-2024': {
        PrimaryColor: '#032EB7',
        SecondaryColor: '#002B9B',
        Headers: {
            WhiteLogo: GcashTechAcademyWhiteLogo,
            WhiteLogoStyles: 'h-[50px]'
        },
        Loaders: {
            WhiteLogo: GcashTechAcademyWhiteLogo,
            WhiteLogoStyles: 'h-[100px]',
            ColoredLogo: GcashTechAcademyBlueLogo
        },
        SignIn: {
            Logo: GcashSigninLogo,
            LogoStyles: 'h-[128px]'
        },
        RegisterUi: {
            Logo: GcashTechAcademyBlueLogo
        }
    },
    'APPER-2024': {
        // PrimaryColor: '#CF5C77',
        PrimaryColor: '#CF5C77',
        SecondaryColor: '#B54E67',
        Headers: {
            WhiteLogo: ApperCorpLogo,
            WhiteLogoStyles: 'h-[30px]'
        },
        Loaders: {
            WhiteLogo: ApperCorpLogo,
            WhiteLogoStyles: 'h-[40px]',
            ColoredLogo: ApperCorpColoredLogo
        },
        SignIn: {
            Logo: ApperCorpColoredLogo,
            LogoStyles: 'h-[72px]'
        },
        RegisterUi: {
            Logo: ApperCorpColoredLogo
        }
    }
};
