import { fetchApi } from './api/ApiGwOptions';

export const createUserQuiz = async (quizPayload) => {
    const createUserQuizQuery = `
        mutation createUserQuiz($input: CreateUserQuizInput) {
            createUserQuiz(input: $input) {
                id
                userId
                courseId
                attempts {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                mostRecentAttempt {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                createdAt
                updatedAt
            }
        }
        `;

    const params = {
        query: createUserQuizQuery,
        variables: { input: quizPayload }
    };

    const response = await fetchApi(params);

    return response.data;
};

export const startUserQuiz = async (quizPayload) => {
    const startUserQuizQuery = `
        mutation startUserQuiz($input: StartUserQuizInput) {
            startUserQuiz(input: $input) {
                id
                userId
                courseId
                attempts {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                mostRecentAttempt {
                    timeStarted
                    timeCompleted
                    totalScore
                    totalItems
                    scoresPerDomain
                }
                createdAt
                updatedAt
            }
        }
        `;

    const params = {
        query: startUserQuizQuery,
        variables: { input: quizPayload }
    };

    const response = await fetchApi(params);

    return response.data.startUserQuiz;
};

export const getUserQuiz = async (getUserQuizInput) => {
    const getCurrentQuizQuery = `
    query getUserQuiz($input: GetUserQuizInput!) {
        getUserQuiz(input: $input) {
            id
            userId
            courseId
            attempts {
                timeStarted
                timeCompleted
                totalScore
                totalItems
                scoresPerDomain
            }
            mostRecentAttempt {
                timeStarted
                timeCompleted
                totalScore
                totalItems
                scoresPerDomain
            }
            createdAt
            updatedAt
        }
    }
`;
    const params = {
        query: getCurrentQuizQuery,
        variables: { input: getUserQuizInput }
    };

    const response = await fetchApi(params);

    return response.data.getUserQuiz;
};

export const submitUserQuiz = async (submitUserQuizInput) => {
    const submitUserQuizQuery = `
        mutation submitUserQuiz($input: SubmitUserQuizInput) {
            submitUserQuiz(input: $input) {
                quiz {
                    id
                    userId
                    courseId
                    attempts {
                        timeStarted
                        timeCompleted
                        totalScore
                        totalItems
                        scoresPerDomain
                    }
                    mostRecentAttempt {
                        timeStarted
                        timeCompleted
                        totalScore
                        totalItems
                        scoresPerDomain
                    }
                    createdAt
                    updatedAt
                }
                questions
            }
        }
        `;

    const params = {
        query: submitUserQuizQuery,
        variables: { input: submitUserQuizInput }
    };

    const response = await fetchApi(params);

    return response.data.submitUserQuiz;
};

//SPRINGBOARD

export const getQuizApi = async (quizId) => {
    const getQuizQuery = `
        query GetQuiz($id: ID!) {
            quiz(id: $id) {
                id
                title
                description
                durationMins
                triesCount
                questions {
                    questionCode
                    questionMd
                    responseType
                    choices {
                        questionChoiceMd
                    }
                }
            }
        }
        `;

    const params = {
        query: getQuizQuery,
        variables: { id: quizId }
    };

    const response = await fetchApi(params, 'springboard');

    return response.data;
};
