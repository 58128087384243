import Deep from '../assets/cldschl/deep.png';
import Accelerate from '../assets/cldschl/accelerate.png';
import GenC from '../assets/cldschl/genc.png';
import Camp from '../assets/cldschl/camp.png';
import AwsFree from '../assets/cldschl/free.png';

export const cloudschoolPathwayDataV2 = [
    {
        image: AwsFree,
        description: 'AWS Examination Preparation',
        disabled: false,
        slug: 'aws-examination-preparation',
        title: 'AWS Examination Preparation',
        id: 'b97e0200-144e-4c28-ace9-895acb54f391'
    },
    {
        image: Accelerate,
        description: 'CloudSchool ACCELERATE',
        disabled: true,
        slug: 'cloudschool-accelerate',
        title: 'CloudSchool ACCELERATE',
        id: 'c882c83e-a597-468c-8382-6e8d395c48a0'
    },
    {
        image: GenC,
        description: 'CloudSchool GenC',
        disabled: true,
        slug: 'cloudschool-genc',
        title: 'CloudSchool GenC',
        id: 'bf3dba5f-cb43-487f-979c-dbaceed49b81'
    },
    {
        image: Camp,
        description: 'CloudSchool CAMP',
        disabled: true,
        slug: 'cloudschool-camp',
        title: 'CloudSchool CAMP',
        id: '06f37382-290f-47bb-92a1-ba28f5379a68'
    },
    {
        image: Deep,
        description: 'CloudSchool DEEP',
        disabled: true,
        slug: 'cloudschool-deep',
        title: 'CloudSchool DEEP',
        id: '7ec2181c-212d-44bc-8f51-8fe97938fa66'
    }
];
