import React, { useContext, useEffect, useState } from 'react';
import { Layout, Typography, Progress, Row, Col, Card, Grid } from 'antd';
import Background from '../components/layout/Background';
import MainHeader from '../components/layout/Header';
import { Content } from 'antd/es/layout/layout';
import { LockOutlined } from '@ant-design/icons';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import PathwayLoader from '../components/pathway/PathwayLoader';
import { UserProviderContextV2 } from '../providers/UserProviderV2';
import '../assets/css/pathway.css';
import Flag from '../assets/flag.png';
import { createCoursesByUser, getCoursesByUserPathway } from '../queries/courses';

import { UIConfig, orgId } from '../config/ui-config';

const Pathway = () => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [courses, setCourses] = useState(null);
    const [isPathwayEmpty, setIsPathwayEmpty] = useState(false);
    const [totalPercent, setTotalPercent] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs, lg } = useBreakpoint();
    const { slug } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const title = searchParams.get('t') || localStorage.getItem('pathway-title');

    const computeTotalPercentage = (totalPercentage, length) => {
        const current = (totalPercentage / (length * 100)) * 100;
        return Math.floor(current); // Round down
    };

    const getCoursesByPathway = async () => {
        //Get courses under the current pathway
        let _courses = await getCoursesByUserPathway(slug, currentUser.id, false, currentUser.orgId);
        //---------------------------------------------------
        //---------------------------------------------------

        if (!_courses) {
            navigate('/');
            return;
        }

        let allCoursesPercentage = 0;
        _courses.map((course) => (allCoursesPercentage = allCoursesPercentage + +course.percent));

        setTotalPercent(computeTotalPercentage(allCoursesPercentage, _courses.length));

        const isEnabled = (percent, deps) => {
            if (deps.length !== 0) {
                let depsCompleted = true;

                for (let i = 0; i < deps.length; i++) {
                    if (_courses.filter((item) => item.id === deps[i])[0]?.percent !== '100') {
                        depsCompleted = false;
                    }
                }

                return depsCompleted;
            }

            if (deps.length === 0 || percent === 100) {
                return true;
            }
        };
        setCourses(
            _courses?.map((crs) => ({
                ...crs,
                disabled: !isEnabled(parseFloat(crs.percent), crs.deps)
            }))
        );
    };

    useEffect(() => {
        const fetchCourses = async () => {
            const _courses = await getCoursesByPathway();
            console.log('COURSES', _courses);
            if (!_courses) {
                setIsPathwayEmpty(true);
            }
        };

        fetchCourses();
    }, []);

    // console.log('IS PATHWAY EMPTY', isPathwayEmpty);

    useEffect(() => {
        const initializeCourses = async () => {
            if (courses && courses.length === 0) {
                //Create initial courses upon initialization
                const _courses = await createCoursesByUser(currentUser.id, currentUser.orgId, slug);
                setCourses(_courses);
                //---------------------------------------------------
                //---------------------------------------------------

                setIsLoading(true);
                await getCoursesByPathway();
                setIsLoading(false);
                window.scrollTo(0, 0);
            }
        };

        if (isPathwayEmpty) {
            initializeCourses();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPathwayEmpty]);

    return (
        <Layout className="relative h-screen">
            <Background orgId={currentUser.orgId} />
            <MainHeader hideWelcome={true} />
            <Content className="relative min-h-screen">
                <Row className="pathway-bg">
                    <div className={`mx-auto w-full max-w-[1920px]  ${xs ? 'p-8' : 'p-16'}`}>
                        <Col className="w-full ">
                            <h4 level={4} className="text-xl font-semibold text-white">
                                Pathway:
                            </h4>
                            <h2 className="mb-10 text-3xl font-semibold text-white">{title}</h2>
                            <p className="text-white">Your current progress:</p>
                            <div className="flex items-center ">
                                <Progress
                                    strokeLinecap="butt"
                                    trailColor="white"
                                    showInfo={false}
                                    percent={totalPercent}
                                    strokeColor={UIConfig[orgId]?.PrimaryColor}
                                    className="progress-text m-0 p-0 pr-4"
                                />
                                <p className="text-right font-bold text-white">{totalPercent}%</p>
                            </div>
                        </Col>

                        {isLoading ? (
                            <div className="mt-14">
                                <PathwayLoader />
                            </div>
                        ) : (
                            <Row gutter={[24, 24]} className="mt-14 items-stretch">
                                {courses?.map((item, index) => {
                                    return (
                                        <Col
                                            key={index}
                                            xs={24}
                                            sm={12}
                                            md={8}
                                            lg={6}
                                            xl={6}
                                            className={`
                                                ${xs ? 'max-w-full' : 'max-w-[360px]'}
                                                ${item.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                            onClick={() => {
                                                if (!item.disabled) {
                                                    navigate(`/course/${item.id}`);
                                                }
                                            }}>
                                            <Card
                                                className="w-full rounded-none border-none bg-transparent"
                                                bodyStyle={{ padding: 0 }}>
                                                <div className="relative flex h-[180px] items-center justify-center bg-black">
                                                    <img
                                                        alt="course-cover"
                                                        src={item.coverPhotoUrl}
                                                        className={`h-full w-full ${
                                                            item.disabled
                                                                ? 'pointer-events-none opacity-10 grayscale'
                                                                : 'pointer-events-auto'
                                                        }`}
                                                    />
                                                    {item.disabled && (
                                                        <LockOutlined className="absolute text-[60px] text-white" />
                                                    )}
                                                </div>
                                                <Row
                                                    className={`flex flex-col justify-between bg-white p-3
                                                        ${lg ? 'h-[120px]' : xs ? 'h-[110px]' : 'h-[140px]'}`}>
                                                    <Text className="pr-4 font-semibold">{item.title}</Text>
                                                    <Row className="mt-4 justify-between">
                                                        <Text>{item.courseCode ? item.courseCode : item.title}</Text>
                                                        <Row>
                                                            <img alt="course-percentage" src={Flag} className="mr-1" />
                                                            <Text className="font-semibold">
                                                                {item.disabled ? '0%' : `${item.percent}%`}
                                                            </Text>
                                                        </Row>
                                                    </Row>
                                                </Row>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </Row>
                        )}
                    </div>
                </Row>
            </Content>
        </Layout>
    );
};

export default Pathway;
