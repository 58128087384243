import { Col, Row, Grid, Button } from 'antd';
import React from 'react';

const DownloadExerciseFiles = ({ currentLesson, exerciseDetails }) => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    // console.log('CURRENT LESSON', currentLesson);

    return (
        <Row
            className={`${
                xs ? 'w-full' : 'w-[40vw]'
            } mx-auto my-20 h-[250px] justify-center border border-solid border-slate-200`}>
            <Col className="mt-12 text-center">
                <h4 className="mb-5 text-lg font-semibold text-primary">Download Course Exercise Files</h4>
                <h5 className="text-gray-600">{exerciseDetails?.title}</h5>
                <Button
                    className={` mt-10`}
                    size="large"
                    type="primary"
                    disabled={!currentLesson.exerFileContent || currentLesson.exerFileContent === '{}'}
                    onClick={() => {
                        if (currentLesson.exerFileContent) {
                            window.open(`${exerciseDetails?.link}`, '_blank');
                        }
                    }}>
                    Download
                </Button>
            </Col>
        </Row>
    );
};

export default DownloadExerciseFiles;
