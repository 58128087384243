import React, { useContext, useEffect, useState } from 'react';
import { Layout, Typography, Col, Row, Space, Grid, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import { UIConfig, orgId } from '../../config/ui-config';
import { mergeNames } from '../../utils/mergeNames';
import Avatar from 'react-avatar';
import moment from 'moment';

const MenuItems = [
    {
        key: '1',
        label: 'Account Settings'
    },
    {
        key: '2',
        label: 'Sign Out'
    }
];

const MainHeader = ({ textPrimary, hideWelcome, primaryLogo, primaryBg }) => {
    const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A'));

    const { Header } = Layout;
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const navigate = useNavigate();

    const appSignOut = () => {
        //delete "UserToken" from localstorage
        localStorage.removeItem('UserToken');
        window.location.reload();
    };

    const handleMenuClick = (e) => {
        if (e.key === '1') {
            navigate('/account-settings'); // Navigate to /account when "Account Settings" is clicked
        } else if (e.key === '2') {
            appSignOut(); // Sign out when "Sign Out" is clicked
        }
    };

    const { userState } = useContext(UserProviderContextV2);
    const { firstName, lastName } = userState?.currentUser;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('hh:mm A'));
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const headerLogo = UIConfig[orgId]?.Headers.WhiteLogo;
    const headerLogoStyles = UIConfig[orgId]?.Headers.WhiteLogoStyles;

    return (
        <Header
            className={`
            ${hideWelcome ? 'h-[180px]' : 'h-[300px]'}
            ${primaryBg ? 'bg-primary' : 'bg-transparent'}
            ${xs ? 'px-9 py-8' : 'py-10'}
            z-10 mx-auto w-full max-w-[1920px]`}>
            {firstName && lastName && (
                <Row className="h-full flex-col justify-between">
                    <Row justify="space-between">
                        <Col>
                            <Space align="center">
                                <img
                                    className={`${headerLogoStyles} cursor-pointer`}
                                    src={headerLogo}
                                    alt="logo"
                                    onClick={() => {
                                        navigate('/');
                                    }}
                                />
                                <Text className={`font-bold ${textPrimary ? 'text-primary' : 'text-white'}`}>
                                    {currentTime}
                                </Text>
                            </Space>
                        </Col>
                        <Col>
                            <Space direction="vertical" size={120}>
                                <Dropdown menu={{ items: MenuItems, onClick: handleMenuClick }}>
                                    <Space align="end" className="cursor-pointer">
                                        <Text
                                            className={`
                                        ${textPrimary ? 'text-primary' : 'text-white'}
                                        ${xs ? 'hidden' : ''}
                                        font-bold`}>
                                            {firstName} {lastName}
                                        </Text>
                                        <div>
                                            <Avatar name={mergeNames(firstName, lastName)} size="40" round={true} />
                                        </div>
                                    </Space>
                                </Dropdown>
                            </Space>
                        </Col>
                    </Row>
                    {!hideWelcome && (
                        <Row>
                            <Text className="text-3xl font-semibold text-white">{`Welcome back, ${firstName}!`}</Text>
                        </Row>
                    )}
                </Row>
            )}
        </Header>
    );
};

export default MainHeader;
