import FECP from '../assets/sa.png';
import SWE from '../assets/agile.png';
import DevOps from '../assets/devops.png';
import CyberSecurity from '../assets/fullstack.png';

export const fecpPathwayDataV2 = [
    {
        image: FECP,
        description: 'GCash FECP Cycle 4',
        disabled: false,
        title: 'GCash FECP Cycle 4',
        id: 'cb60032f-2c3c-4b52-9817-d9e86d72f0cb'
    },
    {
        image: SWE,
        description: 'The 2024 GCash Cadetship Program Specialization Pathway for Software Engineering.',
        disabled: false,
        title: 'Software Engineering Specialization Pathway',
        id: 'c6779c17-653d-43d9-929d-5c5386d2f61e'
    },
    {
        image: DevOps,
        description: 'The 2024 GCash Cadetship Program Specialization Pathway for DevOps.',
        disabled: false,
        title: 'DevOps Specialization Pathway',
        id: '17ca0216-fd5a-47a1-ac2f-a1c5aa87aac6'
    },
    {
        image: CyberSecurity,
        description: 'The 2024 GCash Cadetship Program Specialization Pathway for Cybersecurity.',
        disabled: false,
        title: 'Cybersecurity Specialization Pathway',
        id: 'f40041ff-654b-4744-96a1-5369a231f648'
    }
];
