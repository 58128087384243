import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../assets/register-image.png';
import { validatePassword } from '../utils/validatePassword';
import axios from 'axios';
import { API_GATEWAY_ENDPOINT } from '../queries/api/ApiGwOptions';
import { UIConfig, orgId } from '../config/ui-config';
import CloudschoolTextLogo from '../assets/cloudschool-text-logo.png';
import { ToastContainer } from 'react-toastify';
import { showErrorToast } from '../utils/ToastError';

const { Option } = Select;

export const registerApiGatewayOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    }
    // withCredentials: true // Include cookies in the request
};

export const gcashCurrentRolesArray = ['Cadet'];

export const gcashDesiredRolesArray = ['Software Engineer', 'DevOps', 'Cybersecurity'];

export const cloudschoolCurrentRolesArray = [
    'Fresh Graduate',
    'Software Developer',
    'QA Tester',
    'Network Professional',
    'Data Engineer',
    'Telecommunications',
    'Non-technology Related'
];

export const cloudschoolDesiredRolesArray = [
    'Solutions Architect',
    'DevOps Engineer',
    'Data Engineer',
    'Data Analyst',
    'Machine Learning Engineer',
    'Security Specialist'
];

const Register = () => {
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [subscribe, setSubscribe] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const layout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
    };

    const isCloudschool = orgId === 'CLDSCHL-2024';
    const subscriptionNoteTitle = isCloudschool ? 'CloudSchool PH' : 'Talentboost Learning Platform';

    const onChange = (e) => {
        setSubscribe(!subscribe);
    };

    const onSubmit = async (values) => {
        setIsLoading(true);

        values.subscribedToNewsLetter = subscribe;
        values.orgId = orgId;
        delete values.confirmPassword;

        try {
            const response = await axios.post(`${API_GATEWAY_ENDPOINT}/register`, values, registerApiGatewayOptions);
            setIsLoading(false);

            if (response.status === 200) {
                setRegisterSuccess(true);
            }
        } catch (error) {
            showErrorToast({ message: error.response.data.message });
            setIsLoading(false);
        }
    };

    const registerLogo = UIConfig[orgId]?.RegisterUi.Logo;

    return (
        <Row className="h-screen w-screen bg-white ">
            <Col className="hidden h-screen w-1/2 lg:block">
                <img src={BackgroundImage} alt="background-image" className="h-full w-full object-cover opacity-50" />
            </Col>
            <Col className="flex h-screen w-full justify-center overflow-y-scroll px-4 pb-24 lg:w-1/2">
                <Col className="mt-16 max-w-[500px] px-8 lg:px-0">
                    <div className="mb-4 h-[56px]">
                        <img src={registerLogo} className="h-full" />
                    </div>
                    {isCloudschool && (
                        <div className="mb-4">
                            <h1 className=" mb-2 text-2xl font-medium">Welcome to</h1>
                            <img src={CloudschoolTextLogo} className=" h-[36px]"></img>
                        </div>
                    )}
                    {!isCloudschool && <h1 className="text-4xl">Welcome to Talentboost!</h1>}

                    {!registerSuccess && (
                        <>
                            <h3 className="mb-4 text-lg">Create your account for free</h3>
                            <Form name="set-password-form" className=" register " {...layout} onFinish={onSubmit}>
                                <Form.Item
                                    className="mb-4"
                                    name="firstName"
                                    rules={[
                                        { required: true, message: 'First Name is required.' },
                                        {
                                            max: 30,
                                            message: 'First Name must be less than or equal to 30 characters.'
                                        },
                                        {
                                            pattern: /^[A-Za-z\s.-]+$/,
                                            message: 'First Name cannot contain special characters.'
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject(
                                                        new Error('First Name cannot be empty or only white spaces.')
                                                    );
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <Input size="large" placeholder="First Name" className="input-centered-round" />
                                </Form.Item>
                                <Form.Item
                                    className="mb-4"
                                    name="lastName"
                                    rules={[
                                        { required: true, message: 'Last Name is required.' },
                                        {
                                            max: 30,
                                            message: 'Last Name must be less than or equal to 20 characters.'
                                        },
                                        {
                                            pattern: /^[A-Za-z\s.-]+$/,
                                            message: 'Last Name cannot contain special characters.'
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (!value || value.trim() === '') {
                                                    return Promise.reject(
                                                        new Error('Last Name cannot be empty or only white spaces.')
                                                    );
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}>
                                    <Input size="large" placeholder="Last Name" className="input-centered-round" />
                                </Form.Item>
                                <Form.Item
                                    className="mb-4"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Email address is required.' },
                                        { type: 'email', message: 'Please enter a valid email address.' }
                                    ]}>
                                    <Input size="large" placeholder="Email Address" className="input-centered-round" />
                                </Form.Item>
                                <Form.Item
                                    name="currentRole"
                                    className=" mb-4"
                                    rules={[{ required: true, message: 'Please select your current role.' }]}>
                                    <Select size="large" placeholder="Current Role" className="select-centered-round">
                                        {(isCloudschool ? cloudschoolCurrentRolesArray : gcashCurrentRolesArray).map(
                                            (item) => {
                                                return (
                                                    <Option value={item} key={item}>
                                                        {item}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="wantedPath"
                                    className=" mb-4"
                                    rules={[{ required: true, message: 'Please select your desired career path.' }]}>
                                    <Select
                                        size="large"
                                        placeholder="Desired Career Path"
                                        className="select-centered-round">
                                        {(isCloudschool ? cloudschoolDesiredRolesArray : gcashDesiredRolesArray).map(
                                            (item) => {
                                                return (
                                                    <Option value={item} key={item}>
                                                        {item}
                                                    </Option>
                                                );
                                            }
                                        )}
                                    </Select>
                                </Form.Item>
                                <Form.Item className="mb-4" name="password" rules={[{ validator: validatePassword }]}>
                                    <Input.Password
                                        size="large"
                                        placeholder="Password"
                                        className="input-centered-round"
                                        id="new-password"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="confirmPassword"
                                    id="confirm-password"
                                    dependencies={['password']}
                                    rules={[
                                        { required: true, message: 'Please confirm your password' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('The passwords do not match'));
                                            }
                                        })
                                    ]}>
                                    <Input.Password
                                        size="large"
                                        placeholder="Confirm Password"
                                        className="input-centered-round"
                                    />
                                </Form.Item>
                                <div className="flex items-start">
                                    <Checkbox onChange={onChange}></Checkbox>
                                    <p className="ml-4">
                                        I would like to subscribe to the newsletter and receive updates, promotions, and
                                        news about {subscriptionNoteTitle}.
                                    </p>
                                </div>
                                <Form.Item className="mb-2">
                                    <Button
                                        type="primary"
                                        size="large"
                                        shape="round"
                                        htmlType="submit"
                                        className=" mb-0 mt-12 w-full font-bold"
                                        loading={isLoading}>
                                        {isLoading ? 'Creating Account' : 'Create Account'}
                                    </Button>
                                </Form.Item>
                                <p className="pb-24 text-center">
                                    Already have an account?{' '}
                                    <span className="cursor-pointer font-bold" onClick={() => navigate('/')}>
                                        Sign In
                                    </span>
                                </p>
                            </Form>
                        </>
                    )}
                    {registerSuccess && (
                        <Col>
                            <h2 className="mb-6 mt-12 text-2xl">Thank you for registering!</h2>
                            <p className="mb-2">
                                To complete your registration, please check your email inbox for a confirmation message.
                                Click the link provided in the email to verify your email address and activate your
                                account.
                            </p>
                            <p>If you don't receive the email within a few minutes, please check your spam folder.</p>
                            <Button
                                size="large"
                                shape="round"
                                htmlType="submit"
                                className=" mb-0 mt-12 border-primary text-primary"
                                onClick={() => navigate('/')}>
                                Go to Sign In Page
                            </Button>
                        </Col>
                    )}
                </Col>
            </Col>
            <ToastContainer />
        </Row>
    );
};

export default Register;
