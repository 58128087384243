import { useContext, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UserProviderContextV2 } from '../../../providers/UserProviderV2';
import { createUserLesson, startUserLesson } from '../../../queries/lessons';

const VideoContent = ({ currentLesson, setCurrentLesson, setLessonStartTime, pathwayId }) => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const startLesson = async () => {
        setLoading(true);

        const createUserLessonInput = {
            userId: currentUser.id,
            lessonId: currentLesson.id,
            courseId: currentLesson.courseId,
            title: currentLesson.title
        };

        const startUserLessonInput = {
            userId: currentUser.id,
            courseId: currentLesson.courseId,
            lessonToStartId: currentLesson.id,
            pathwayId: pathwayId
        };

        if (currentLesson.insert) {
            await createUserLesson(createUserLessonInput);
        }

        const newLessonData = await startUserLesson(startUserLessonInput);

        const timestamp = parseInt(!newLessonData.timeStarted ? 0 : newLessonData.timeStarted);

        setCurrentLesson({
            ...currentLesson,
            status: newLessonData.status,
            timeStarted: newLessonData.timeStarted
        });

        setLessonStartTime(() => Date.now(timestamp));
        setLoading(false);
    };

    return (
        <>
            <h1 className="mb-5 text-5xl font-semibold">{currentLesson.title}</h1>
            {currentLesson.status === 'NOT_STARTED' && (
                <div className={`mb-5 flex h-[300px] gap-2 `}>
                    <Button
                        style={{}}
                        size="large"
                        onClick={() => {
                            navigate(`/`);
                        }}>
                        Go back to home
                    </Button>
                    <Button
                        className="bg-primary text-white"
                        size="large"
                        loading={loading}
                        onClick={() => {
                            startLesson();
                        }}>
                        Start lesson
                    </Button>
                </div>
            )}
            {currentLesson.status !== 'NOT_STARTED' && (
                <iframe
                    src={`https://muse.ai/embed/${currentLesson.videoContent}?search=0&logo=0`}
                    width="100%"
                    height="100%"
                    title={currentLesson.title}
                    allowFullScreen></iframe>
            )}
        </>
    );
};

export default VideoContent;
