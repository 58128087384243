import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_GATEWAY_ENDPOINT, fetchApi } from '../queries/api/ApiGwOptions';
import { registerApiGatewayOptions } from './Register';
import BackgroundImage from '../assets/register-image.png';
import { Button, Col, Row } from 'antd';
import PageLoader from '../components/layout/loaders/PageLoader';
import { UIConfig, orgId } from '../config/ui-config';
import CloudschoolTextLogo from '../assets/cloudschool-text-logo.png';

const VerifyEmail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [requestBody, setRequestBody] = useState({
        urlBody: null
    });

    const [response, setResponse] = useState(null);

    const isCloudschool = orgId === 'CLDSCHL-2024';
    const id = searchParams.get('id');

    useEffect(() => {
        const key = searchParams.get('key');
        setRequestBody({ ...requestBody, urlBody: key });
    }, [location.search]);

    useEffect(() => {
        if (requestBody.urlBody) {
            verifyEmail();
        }
    }, [requestBody]);

    const verifyEmail = async () => {
        try {
            const res = await axios.put(`${API_GATEWAY_ENDPOINT}/verifyemail`, requestBody, registerApiGatewayOptions);
            setResponse(res);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };

    const logo = UIConfig[orgId]?.RegisterUi.Logo;

    return !response?.data?.message ? (
        <PageLoader />
    ) : (
        <Row className="h-screen w-screen">
            <Col className=" hidden h-screen w-1/2 bg-white lg:block">
                <img src={BackgroundImage} alt="background-image" className="h-full w-full object-cover opacity-50" />
            </Col>
            <Col className="flex h-full w-full bg-white lg:w-1/2">
                <Col className=" mt-24 max-w-[500px] px-12 ">
                    <div className="mb-4 h-[56px]">
                        <img src={logo} className="h-full" />
                    </div>
                    {isCloudschool && (
                        <div className="mb-4">
                            <h1 className=" mb-2 text-2xl font-medium">Welcome to</h1>
                            <img src={CloudschoolTextLogo} className=" h-[36px]"></img>
                        </div>
                    )}
                    {!isCloudschool && <h1 className="text-4xl">Welcome to Talentboost!</h1>}

                    <Col className="w-full">
                        <h2 className="mb-2 mt-12 text-3xl">{response?.data?.message}</h2>
                        <p className="mb-2">Thank you for verifying your email address. Happy learning!</p>

                        <Button
                            size="large"
                            shape="round"
                            htmlType="submit"
                            className=" mb-0 mt-12 border-primary text-primary"
                            onClick={() => navigate('/')}>
                            Go to Sign In Page
                        </Button>
                    </Col>
                </Col>
            </Col>
        </Row>
    );
};

export default VerifyEmail;
