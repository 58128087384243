import { useContext, useState } from 'react';
import { Button } from 'antd';
import { FiExternalLink } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { UserProviderContextV2 } from '../../../providers/UserProviderV2';
import { createUserLesson, startUserLesson } from '../../../queries/lessons';

const UrlContent = ({ currentLesson, setCurrentLesson, lessonStartTime, setLessonStartTime, pathwayId }) => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const startLesson = async () => {
        setLoading(true);

        const createUserLessonInput = {
            userId: currentUser.id,
            lessonId: currentLesson.id,
            courseId: currentLesson.courseId,
            title: currentLesson.title
        };

        const startUserLessonInput = {
            userId: currentUser.id,
            courseId: currentLesson.courseId,
            lessonToStartId: currentLesson.id,
            pathwayId: pathwayId
        };

        if (currentLesson.insert) {
            await createUserLesson(createUserLessonInput);
        }

        const newLessonData = await startUserLesson(startUserLessonInput);

        const timestamp = parseInt(!newLessonData.timeStarted ? 0 : newLessonData.timeStarted);

        setCurrentLesson({
            ...currentLesson,
            status: newLessonData.status,
            timeStarted: newLessonData.timeStarted
        });

        setLessonStartTime(() => Date.now(timestamp));
        setLoading(false);
    };

    return (
        <>
            <h1 className="mb-5 text-5xl font-semibold">{currentLesson.title}</h1>
            {currentLesson.status === 'NOT_STARTED' && (
                <div className={`mb-5 flex h-[300px] gap-2 `}>
                    <Button
                        style={{}}
                        size="large"
                        onClick={() => {
                            navigate(`/`);
                        }}>
                        Go back to home
                    </Button>
                    <Button
                        className="bg-primary text-white"
                        size="large"
                        onClick={() => {
                            startLesson();
                        }}>
                        Start lesson
                    </Button>
                </div>
            )}
            {currentLesson.status !== 'NOT_STARTED' && (
                <div
                    style={{
                        width: 'full',
                        height: '240px',
                        border: '1px solid gray',
                        borderRadius: '16px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                    <img
                        src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        alt="External Link"></img>
                    <Button
                        className="flex items-center"
                        style={{ marginTop: '16px', borderRadius: '50px' }}
                        size="large"
                        type="primary"
                        loading={loading}
                        onClick={() => {
                            window.open(`${currentLesson.urlContent}`, '_blank');
                        }}>
                        View Lesson
                        <FiExternalLink style={{ marginLeft: '8px' }} />
                    </Button>
                </div>
            )}
        </>
    );
};

export default UrlContent;
