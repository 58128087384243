export const validatePassword = (_, value) => {
    if (!value) {
        return Promise.reject(new Error('Please input password'));
    }

    if (value.length < 8) {
        return Promise.reject(new Error('Password must be at least 8 characters'));
    }

    if (value.length > 30) {
        return Promise.reject(new Error('Password must not exceed 30 characters'));
    }

    if (!/(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(value)) {
        return Promise.reject(
            new Error('Password must contain at least one capital letter, one numeric value, and one special character')
        );
    }

    return Promise.resolve();
};
