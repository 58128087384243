import axios from 'axios';

export const token = localStorage.getItem('PeopleMgrToken');
export const userV2Token = localStorage.getItem('UserToken');
export const API_GATEWAY_ENDPOINT = process.env.REACT_APP_API_GATEWAY_ENDPOINT;

export const fetchApi = async (params, endpoint = 'learner') => {
    const userV2Token = localStorage.getItem('UserToken');
    const apiGatewayOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'X-Api-Key': userV2Token
        }
    };

    try {
        const response = await axios.post(`${API_GATEWAY_ENDPOINT}/${endpoint}`, params, apiGatewayOptions);
        return response;
    } catch (error) {
        if (error.response && error.response.status === 403) {
            window.location.href = '/';
        }
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('UserToken');
            window.location.href = '/signin?sessionExpired=true';
        } else {
            console.error('Error fetching user data:', error);
        }
    }
};

export const apiGatewayOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': userV2Token
    }
};
