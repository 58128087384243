import { fetchApi } from './api/ApiGwOptions';

export const updateUserInformation = async (email, orgId, firstName, lastName) => {
    const updateUserInformationMutation = `
        mutation updateUserInformation($input: UpdateUserInformationInput!) {
            updateUserInformation(input: $input) {
                success
                message
            }
        }
    `;

    const input = {
        email: email,
        orgId: orgId,
        firstName: firstName,
        lastName: lastName
    };

    const params = {
        query: updateUserInformationMutation,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data.updateUserInformation;
};

export const updateUserPassword = async (email, orgId, currentPassword, newPassword) => {
    const updateUserPasswordMutation = `
        mutation updateUserPassword($input: UpdateUserPasswordInput!) {
            updateUserPassword(input: $input) {
                success
                message
            }
        }
    `;

    const input = {
        email: email,
        orgId: orgId,
        currentPassword: currentPassword,
        newPassword: newPassword
    };

    const params = {
        query: updateUserPasswordMutation,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data.updateUserPassword;
};
