import React, { useContext, useEffect, useState } from 'react';
import { Layout, Typography, Col, Row, Space, Grid, Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { UIConfig, orgId } from '../../config/ui-config';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import { mergeNames } from '../../utils/mergeNames';
import moment from 'moment';
import Avatar from 'react-avatar';

const MenuItems = [
    {
        key: '1',
        label: 'Account Settings'
    },
    {
        key: '2',
        label: 'Sign Out'
    }
];

const CoursePageHeader = () => {
    const [currentTime, setCurrentTime] = useState(moment().format('hh:mm A'));

    const { Header } = Layout;
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const appSignOut = () => {
        //delete "UserToken" from localstorage
        localStorage.removeItem('UserToken');
        window.location.reload();
    };

    const handleMenuClick = (e) => {
        if (e.key === '1') {
            navigate('/account-settings'); // Navigate to /account when "Account Settings" is clicked
        } else if (e.key === '2') {
            appSignOut(); // Sign out when "Sign Out" is clicked
        }
    };

    const { userState } = useContext(UserProviderContextV2);

    const { firstName, lastName } = userState?.currentUser;

    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(moment().format('hh:mm A'));
        }, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const headerLogo = UIConfig[orgId]?.Headers.WhiteLogo;
    const headerLogoStyles = UIConfig[orgId]?.Headers.WhiteLogoStyles;
    return (
        <div className="flex justify-center  bg-primary">
            <Header
                className={`
                ${xs ? 'px-4' : 'px-8'}
                z-10 flex h-full w-full max-w-[1920px] items-center justify-between bg-primary py-4`}>
                {firstName && lastName && (
                    <Row className="my-auto w-full flex-col">
                        <Row justify="space-between">
                            <Col className="flex">
                                <Space>
                                    <img
                                        src={headerLogo}
                                        alt="logo"
                                        className={headerLogoStyles}
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                    <Text className={`font-bold text-white`}>{currentTime}</Text>
                                </Space>
                            </Col>
                            <Col>
                                <Space direction="vertical" size={120}>
                                    <Dropdown menu={{ items: MenuItems, onClick: handleMenuClick }}>
                                        <Space align="center" className="cursor-pointer">
                                            <Text
                                                className={`
                                           text-white ${xs ? 'hidden' : ''} font-bold`}>
                                                {firstName} {lastName}
                                            </Text>
                                            <div>
                                                <Avatar name={mergeNames(firstName, lastName)} size="40" round={true} />
                                            </div>
                                        </Space>
                                    </Dropdown>
                                </Space>
                            </Col>
                        </Row>
                    </Row>
                )}
            </Header>
        </div>
    );
};

export default CoursePageHeader;
