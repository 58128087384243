import React, { useContext, useState } from 'react';
import CoursePageHeader from '../components/layout/CoursePageHeader';
import { Form, Input, Layout } from 'antd';
import { UserProviderContextV2 } from '../providers/UserProviderV2';
import { validatePassword } from '../utils/validatePassword';
import ReusableModal from '../components/common/ReusableModal';
import { updateUserInformation, updateUserPassword } from '../queries/users';
import { ToastContainer } from 'react-toastify';
import { showErrorToast } from '../utils/ToastError';
import { showSuccessToast } from '../utils/ToastSuccess';

const AccountSettings = () => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;

    const [currentPage, setCurrentPage] = useState('userInformation');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false); // Track if any changes were made

    const showModal = (values) => {
        setFormValues(values); // Save the form values temporarily
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        setIsLoading(true);
        try {
            if (currentPage === 'userInformation') {
                const updateUserInformationResponse = await updateUserInformation(
                    currentUser.email,
                    currentUser.orgId,
                    formValues.firstName,
                    formValues.lastName
                );

                if (updateUserInformationResponse.success) {
                    showSuccessToast({ message: updateUserInformationResponse.message });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                } else {
                    showErrorToast({ message: updateUserInformationResponse.message });
                }
            }

            if (currentPage === 'updatePassword') {
                const updateUserPasswordResponse = await updateUserPassword(
                    currentUser.email,
                    currentUser.orgId,
                    formValues.currentPassword,
                    formValues.newPassword
                );

                if (updateUserPasswordResponse.success) {
                    showSuccessToast({
                        message: `${updateUserPasswordResponse.message} Please sign-in again to continue.`
                    });

                    setTimeout(() => {
                        localStorage.removeItem('UserToken');
                        window.location.reload();
                    }, 2000);
                } else {
                    showErrorToast({ message: updateUserPasswordResponse.message });
                }
            }
        } catch (error) {
            console.error('An error occurred while updating:', error);
            showErrorToast({ message: 'Failed to update. Please try again later.' });
        } finally {
            setIsModalVisible(false); // Always hide modal regardless of success or failure
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onFinish = (values) => {
        showModal(values); // Show the confirmation modal
    };

    // Track form value changes
    const handleValuesChange = (_, allValues) => {
        const { firstName, lastName } = allValues;
        // Check if firstName or lastName has changed from the initial values
        const hasChanged = firstName !== currentUser.firstName || lastName !== currentUser.lastName;
        setIsChanged(hasChanged); // Update the state to track if changes were made
    };

    return (
        <>
            <Layout>
                <CoursePageHeader hideWelcome={true} primaryBg={true} />
                <div className="mx-auto h-[calc(100vh-96px)] w-full max-w-[1920px] px-4 py-12 sm:px-6 lg:px-16">
                    <div className="flex h-full w-full flex-col lg:flex-row">
                        <div className="w-full border-b-2 border-gray-200 pb-6 lg:w-1/4 lg:border-b-0 lg:border-r-2 lg:pb-0 lg:pr-12">
                            <h1 className="text-xl font-semibold sm:text-2xl">Account Settings</h1>
                            <ul className="flex gap-4 lg:mt-0 lg:flex-col">
                                <li
                                    className={`mt-4 cursor-pointer rounded-md p-2 lg:p-4 ${currentPage === 'userInformation' ? 'bg-gray-200 font-semibold text-primary' : 'border lg:border-0'}`}
                                    onClick={() => setCurrentPage('userInformation')}>
                                    User Information
                                </li>
                                <li
                                    className={`mt-4 cursor-pointer rounded-md p-2 lg:mt-0  lg:p-4 ${currentPage === 'updatePassword' ? 'bg-gray-200 font-semibold text-primary' : 'border lg:border-0'}`}
                                    onClick={() => setCurrentPage('updatePassword')}>
                                    Update Password
                                </li>
                            </ul>
                        </div>
                        <div className="w-full pt-6 lg:w-3/4 lg:pl-12 lg:pt-0">
                            <h1 className="text-xl font-semibold sm:text-2xl">Hi {currentUser.firstName}!</h1>
                            <div>
                                <p className="mb-4 mt-2 sm:mb-8">
                                    Update your{' '}
                                    {currentPage === 'userInformation' ? 'personal information' : 'password'} here:
                                </p>

                                {currentPage === 'userInformation' && (
                                    <Form
                                        className="w-full space-y-4 sm:space-y-6"
                                        initialValues={{
                                            firstName: currentUser.firstName,
                                            lastName: currentUser.lastName,
                                            email: currentUser.email
                                        }}
                                        onValuesChange={handleValuesChange} // Track value changes
                                        onFinish={onFinish}>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="firstName"
                                                className="block text-sm font-medium text-gray-700">
                                                First Name:
                                            </label>
                                            <Form.Item
                                                name="firstName"
                                                rules={[
                                                    { required: true, message: 'First Name is required.' },
                                                    {
                                                        max: 30,
                                                        message:
                                                            'First Name must be less than or equal to 30 characters.'
                                                    },
                                                    {
                                                        pattern: /^[A-Za-z\s.-]+$/,
                                                        message: 'First Name cannot contain special characters.'
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value || value.trim() === '') {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        'First Name cannot be empty or only white spaces.'
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}>
                                                <Input size="large" />
                                            </Form.Item>
                                        </div>

                                        <div className="space-y-2">
                                            <label
                                                htmlFor="lastName"
                                                className="block text-sm font-medium text-gray-700">
                                                Last Name:
                                            </label>
                                            <Form.Item
                                                name="lastName"
                                                rules={[
                                                    { required: true, message: 'Last Name is required.' },
                                                    {
                                                        max: 30,
                                                        message:
                                                            'Last Name must be less than or equal to 20 characters.'
                                                    },
                                                    {
                                                        pattern: /^[A-Za-z\s.-]+$/,
                                                        message: 'Last Name cannot contain special characters.'
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            if (!value || value.trim() === '') {
                                                                return Promise.reject(
                                                                    new Error(
                                                                        'Last Name cannot be empty or only white spaces.'
                                                                    )
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}>
                                                <Input size="large" />
                                            </Form.Item>
                                        </div>

                                        <div className="space-y-2">
                                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                Email Address:
                                            </label>
                                            <Form.Item name="email">
                                                <Input size="large" disabled />
                                            </Form.Item>
                                        </div>

                                        <button
                                            type="submit"
                                            disabled={!isChanged} // Disable button if no changes
                                            className={`w-full rounded-md  bg-primary px-4 py-2 text-sm font-medium text-white sm:w-auto ${!isChanged ? 'cursor-not-allowed  opacity-50' : ''}`}>
                                            Save Changes
                                        </button>
                                    </Form>
                                )}
                            </div>
                            {currentPage === 'updatePassword' && (
                                <div>
                                    <Form className="w-full space-y-4 sm:space-y-6" onFinish={onFinish}>
                                        <div className="space-y-2">
                                            <label
                                                htmlFor="currentPassword"
                                                className="block text-sm font-medium text-gray-700">
                                                Current Password:
                                            </label>
                                            <Form.Item
                                                name="currentPassword"
                                                rules={[{ required: true, message: 'Please input current password' }]}>
                                                <Input.Password
                                                    size="large"
                                                    placeholder="Current Password"
                                                    className=""
                                                />
                                            </Form.Item>
                                        </div>

                                        <div className="space-y-2">
                                            <label
                                                htmlFor="newPassword"
                                                className="block text-sm font-medium text-gray-700">
                                                New Password:
                                            </label>
                                            <Form.Item name="newPassword" rules={[{ validator: validatePassword }]}>
                                                <Input.Password size="large" placeholder="New Password" className="" />
                                            </Form.Item>
                                        </div>

                                        <div className="space-y-2">
                                            <label
                                                htmlFor="confirmPassword"
                                                className="block text-sm font-medium text-gray-700">
                                                Confirm Password:
                                            </label>
                                            <Form.Item
                                                name="confirmPassword"
                                                rules={[
                                                    { required: true, message: 'Please confirm new password' },
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (!value || getFieldValue('newPassword') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject(
                                                                new Error('The passwords do not match')
                                                            );
                                                        }
                                                    })
                                                ]}>
                                                <Input.Password
                                                    size="large"
                                                    placeholder="Confirm Password"
                                                    className=""
                                                />
                                            </Form.Item>
                                        </div>

                                        <button
                                            type="submit"
                                            className="w-full rounded-md bg-primary px-4 py-2 text-sm font-medium text-white sm:w-auto">
                                            Save Changes
                                        </button>
                                    </Form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>

            <ReusableModal
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                message={`Are you sure you want to save these changes?`}
                okText={isLoading ? 'Saving Changes' : 'Save Changes'}
                confirmLoading={isLoading}
            />

            <ToastContainer />
        </>
    );
};

export default AccountSettings;
