import { fetchApi } from './api/ApiGwOptions';

export const createCoursesByUser = async (userId, orgId, pathwayId) => {
    const createCoursesByUserMutation = `
        mutation createCoursesByUsers($input: CreateCoursesByUserInput!) {
            createCoursesByUsers(input: $input) {
                id
                userId
                pathwayId
                title
                courseCode
                description
                coverPhotoUrl
                deps
                percent
                createdAt
                updatedAt
            }
        }`;

    const input = {
        userId: userId,
        orgId: orgId,
        pathwayId: pathwayId
    };
    const params = {
        query: createCoursesByUserMutation,
        variables: { input }
    };

    const response = await fetchApi(params, 'learner');
    // console.log('RESPONSE', response);
    return response?.data?.createCoursesByUser;
};

export const getCoursesByUserPathway = async (pathwayId, userId, getAllCourses, orgId) => {
    const getCoursesByUserPathwaysQuery = `
        query getCoursesByUserPathway($input: GetCoursesByUserPathwayInput!) {
            getCoursesByUserPathway(input: $input) {
                id
                userId
                courseCode
                pathwayId
                title
                description
                coverPhotoUrl
                deps
                percent
                status
                createdAt
                updatedAt
            }
        }
    `;
    const input = {
        pathwayId: pathwayId,
        userId: userId,
        getAllCourses: getAllCourses,
        orgId: orgId
    };

    const params = {
        query: getCoursesByUserPathwaysQuery,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data.getCoursesByUserPathway;
};

export const startCurrentCourse = async (lessonData, courseId, currentLessonId, userId, orgId, pathwayId) => {
    const startUserCourseMutation = `
        mutation StartUserCourse($input: StartCourseInput) {
            startUserCourse(input: $input) {
                id
                userId
                courseCode
                pathwayId
                title
                description
                coverPhotoUrl
                deps
                percent
                status
                createdAt
                updatedAt
            }
        }
        `;

    const input = {
        lessonData: lessonData,
        courseId: courseId,
        currentLessonId: currentLessonId,
        userId: userId,
        orgId: orgId,
        pathwayId: pathwayId
    };

    const params = {
        query: startUserCourseMutation,
        variables: { input }
    };

    const response = await fetchApi(params);

    return response?.data;
};

export const getCurrentCourse = async (userId, courseId) => {
    const getCurrentCourseByUserAndIdQuery = `
        query getCurrentCourseByUserAndId($input: GetCurrentCourseByUserAndIdInput!) {
            getCurrentCourseByUserAndId(input: $input) {
                id
                userId
                title
                pathwayId
                description
                deps
                percent
                status
                schedule {
                    id
                    schedule
                    sessionItems {
                        sessionItemId
                        schedule
                    }
                }
            }
        }
        `;

    const input = {
        userId: userId,
        courseId: courseId
    };

    const params = {
        query: getCurrentCourseByUserAndIdQuery,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data.getCurrentCourseByUserAndId;
};

// SPRINGBOARD
export const fetchCourseModules = async (id) => {
    const getCourseModulesQuery = `
        query cycle($id: ID!) {
            cycle(id: $id) {
                id
                title
                description
                sessions {
                    id
                    title
                    description
                    sessionItems {
                        ... on Lab {
                            __typename
                            id
                            title
                            contentType
                            videoContent
                            urlContent
                            exerFileContent
                            description
                            createdAt
                        }
                        ...on Quiz {
                            __typename
                            id
                            title
                            description
                            createdAt
                        }
                        ...on Assignment {
                            __typename
                            id
                            title
                            description
                            createdAt
                        }
                    }
                }
            }
        }
`;

    const params = {
        query: getCourseModulesQuery,
        variables: { id: id }
    };

    const response = await fetchApi(params, 'springboard');
    return response.data;
};

// former GetCourseModuleQuery from SpringboardAPI.js
export const fetchCourseLessons = async (contentId) => {
    const getCourseLessonsQuery = `
        query lab($id: ID!) {
            lab(id: $id) {
                id
                title
                description
                bodyMd
                contentType
                videoContent
                urlContent
                exerFileContent
                durationMins
            }
        }
        `;

    const params = {
        query: getCourseLessonsQuery,
        variables: { id: contentId }
    };

    const response = await fetchApi(params, 'springboard');

    return response.data;
};
