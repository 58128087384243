export const flattenedSessionItemsToLesson = (sessions) => {
    let seenIds = new Set();

    return sessions.reduce((accumulator, currentSession) => {
        const modifiedItems = currentSession.sessionItems
            .map((item) => ({
                id: item.id,
                title: item.title,
                description: item.description,
                typename: item.__typename
            }))
            .filter((item) => {
                if (seenIds.has(item.id)) {
                    return false;
                }

                seenIds.add(item.id);
                return true;
            });

        return accumulator.concat(modifiedItems);
    }, []);
};
