import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Signin from './pages/UserSignin';

import HomePage, { Dashboard } from './pages/Home';
import CoursePage from './pages/Course';
import Pathway from './pages/Pathway';
import CourseIntro from './components/course/CourseIntro';
import CourseContent from './components/course/CourseContent';
import Register from './pages/Register';
import VerifyEmail from './pages/VerifyEmail';
import UserV2Signin from './pages/UserV2Signin';
import { AuthProviderV2 } from './providers/AuthProviderV2';
import { UserProviderV2 } from './providers/UserProviderV2';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import AccountSettings from './pages/AccountSettings';

export const routeConfig = {
    //UN-AUTHENTICATED ROUTES
    register: {
        path: '/register',
        component: () => {
            return <Register />;
        }
    },
    verifyEmail: {
        path: '/verifyemail',
        component: () => {
            return <VerifyEmail />;
        }
    },
    signIn: {
        path: '/signin',
        component: () => {
            return <UserV2Signin />;
        }
    },
    forgotPassword: {
        path: '/forgot-password',
        component: () => {
            return <ForgotPassword />;
        }
    },
    resetPassword: {
        path: '/reset-password',
        component: () => {
            return <ResetPassword />;
        }
    },

    //AUTHENTICATED ROUTES
    accountSettings: {
        path: '/account-settings',
        component: () => {
            return (
                <ProtectedRoute>
                    <AccountSettings />
                </ProtectedRoute>
            );
        }
    },
    pathwayV2: {
        path: '/pathway/:slug',
        component: () => {
            return (
                <ProtectedRoute>
                    <Pathway />
                </ProtectedRoute>
            );
        }
    },
    course: {
        path: 'course/:slug',
        component: () => {
            return (
                <ProtectedRoute>
                    <CoursePage />
                </ProtectedRoute>
            );
        },
        courseIntro: {
            component: () => {
                return (
                    <ProtectedRoute>
                        <CourseIntro />
                    </ProtectedRoute>
                );
            }
        },
        courseContent: {
            path: 'cc/:contentId',
            component: () => {
                return (
                    <ProtectedRoute>
                        <CourseContent />
                    </ProtectedRoute>
                );
            }
        }
    },
    home: {
        path: '/',
        component: () => {
            return (
                <ProtectedRoute>
                    <HomePage />
                </ProtectedRoute>
            );
        },
        dashboard: {
            component: () => {
                return (
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                );
            }
        }
    }
};

const App = () => {
    return (
        <Router>
            <UserProviderV2>
                <AuthProviderV2>
                    <Routes>
                        {/* Learner Routes */}
                        <Route path={routeConfig.home.path} element={routeConfig.home.component()}>
                            <Route index element={routeConfig.home.dashboard.component()} />
                        </Route>

                        {/* Register */}
                        <Route path={routeConfig.register.path} element={routeConfig.register.component()} />
                        <Route path={routeConfig.verifyEmail.path} element={routeConfig.verifyEmail.component()} />
                        {/* Sign-in */}
                        <Route path={routeConfig.signIn.path} element={routeConfig.signIn.component()} />
                        <Route
                            path={routeConfig.forgotPassword.path}
                            element={routeConfig.forgotPassword.component()}
                        />
                        <Route path={routeConfig.resetPassword.path} element={routeConfig.resetPassword.component()} />
                        <Route
                            path={routeConfig.accountSettings.path}
                            element={routeConfig.accountSettings.component()}
                        />
                        <Route path={routeConfig.pathwayV2.path} element={routeConfig.pathwayV2.component()} />
                        <Route path={routeConfig.course.path} element={routeConfig.course.component()}>
                            <Route index element={routeConfig.course.courseIntro.component()} />
                            <Route
                                path={routeConfig.course.courseContent.path}
                                element={routeConfig.course.courseContent.component()}
                            />
                        </Route>
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                </AuthProviderV2>
            </UserProviderV2>
        </Router>
    );
};

export default App;
