import { Row, Space, Progress, Col, Grid } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import { getCoursesByUserPathway } from '../../queries/courses';
import { getPathwayTitle } from '../../utils/getPathwayTitle';
import isPercentInProg from '../../utils/isPercentInProg';
import CourseStatusLoader from '../layout/loaders/CourseStatusLoader';
import EmptyListIndicator from '../layout/EmptyListIndicator';

const InProgressContent = () => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [courses, setCourses] = useState([]);
    const [inProgressCourses, setInProgressCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { useBreakpoint } = Grid;
    const { md, xs } = useBreakpoint();
    const navigate = useNavigate();

    useEffect(() => {
        const getPathways = async () => {
            setIsLoading(true);

            //Get all courses, for display in the in progress section
            let _courses = await getCoursesByUserPathway('', currentUser.id, true, currentUser.orgId);
            setCourses(_courses);
            //---------------------------------------------------
            //---------------------------------------------------

            setIsLoading(false);
        };

        getPathways();
    }, []);
    useEffect(() => {
        setInProgressCourses(() => {
            return courses.filter((item) => {
                // return isPercentInProg(item.percent);
                return item.status === 'STARTED' && item.percent !== '100';
            });
        });
    }, [courses]);

    return (
        <Space direction="vertical" className="mt-12 w-full">
            <Col>
                {isLoading ? (
                    <CourseStatusLoader />
                ) : (
                    <>
                        {inProgressCourses?.length === 0 ? (
                            <EmptyListIndicator message={'You have no on-going courses.'} />
                        ) : (
                            <>
                                <h2 className="mb-4 text-xl font-semibold text-white ">In Progress</h2>
                                {inProgressCourses.map((item, index) => {
                                    return (
                                        <div className="bg-white" key={index}>
                                            <Row
                                                onClick={() => {
                                                    navigate(`/course/${item.id}`);
                                                }}
                                                style={{
                                                    background:
                                                        'linear-gradient(-320deg, rgba(100, 150, 200, 0.2), rgba(206, 157, 44, 0.3))'
                                                }}
                                                className="mb-4 cursor-pointer items-center justify-between p-4 ">
                                                <Row className={`gap-4 ${md ? 'w-[80%]' : 'w-full'}`}>
                                                    <Row className={xs ? 'h-full w-full' : 'h-[100px] w-[100px]'}>
                                                        <img
                                                            alt="course-cover"
                                                            src={item.coverPhotoUrl}
                                                            className="h-full w-full object-cover"
                                                        />
                                                    </Row>
                                                    <Row
                                                        className={`items-center" flex flex-col justify-between ${
                                                            xs ? 'gap-2' : ''
                                                        } `}>
                                                        <h4 className="text-gray-500">
                                                            {getPathwayTitle(item.pathwayId, currentUser.orgId)}
                                                        </h4>
                                                        <h2 className="text-lg font-semibold">{item.title}</h2>
                                                        <h4>You are currently working on this</h4>
                                                    </Row>
                                                </Row>
                                                <div className={`${xs ? 'mt-2' : ''}`}>
                                                    <Progress
                                                        type="circle"
                                                        percent={parseFloat(item.percent)}
                                                        size={md ? 80 : 40}
                                                    />
                                                </div>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </Col>
        </Space>
    );
};

export default InProgressContent;
