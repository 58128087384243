import React, { useEffect, useRef, useState } from 'react';
import CoachAiah from '../../../assets/coach-aiah.png';
import { Grid, Tooltip } from 'antd';
import { format, parseISO } from 'date-fns';
import { getAllMessages, sendNewMessage } from '../../../queries/chats';
import { useLocation } from 'react-router-dom';
import { FaInfoCircle } from 'react-icons/fa';

const OpenAiChatbot = ({
    isChatOpen,
    setIsChatOpen,
    currentUser,
    currentLesson,
    message,
    setMessage,
    messages,
    setMessages
}) => {
    const MAX_TOKENS = 10;
    const location = useLocation();
    const [isTyping, setIsTyping] = useState(false);
    const [tokensLeft, setTokensLeft] = useState(MAX_TOKENS);
    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    const isSendDisabled = tokensLeft <= 0;

    useEffect(() => {
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, isChatOpen]);

    useEffect(() => {
        const fetchMessages = async () => {
            if (isChatOpen && inputRef.current) {
                inputRef.current.focus();
            }
            if (isChatOpen) {
                if (messages?.length === 0) {
                    const _messages = await getAllMessages(
                        currentUser.id,
                        currentLesson.id,
                        currentLesson.courseId,
                        currentUser.email,
                        currentLesson.title
                    );
                    setMessages(_messages.allMessages);

                    const availableTokens = MAX_TOKENS - _messages.userMessageCount;
                    setTokensLeft(availableTokens);
                }
            }
        };

        fetchMessages();
    }, [isChatOpen, location]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isChatOpen) {
                toggleChat();
            }
        };

        if (isChatOpen) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        // Cleanup event listener on component unmount or when chatbox is closed
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isChatOpen, toggleChat]);

    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const chatEndRef = useRef(null); // Ref for scrolling to the bottom of chat
    const inputRef = useRef(null);

    const handleInputChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        const newMessage = {
            id: messages.length + 1,
            role: 'user',
            content: message.trim(),
            createdAt: new Date().toISOString(),
            isDeleted: false
        };
        setMessages([...messages, newMessage]);
        setMessage('');
        setTokensLeft(tokensLeft - 1);
        setIsTyping(true);

        const botResponse = await sendNewMessage(
            currentUser.id,
            currentLesson.id,
            currentLesson.courseId,
            message,
            'user',
            currentUser.email,
            formatMessagesArray(messages)
        );

        setIsTyping(false);
        setMessages([...messages, newMessage, botResponse]);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    function formatMessagesArray(array) {
        return array.map(({ content, role }) => ({ content, role }));
    }

    const DateFormatComponent = ({ dateString }) => {
        const date = parseISO(dateString);
        const formattedDate = format(date, 'PPpp'); // 'PPpp' is a token for a readable date and time

        return formattedDate;
    };
    return (
        <div
            className={`fixed z-50 ${!xs ? 'bottom-8 right-8' : isChatOpen ? 'bottom-0 right-0 p-2' : 'bottom-2 right-2'}`}>
            {isChatOpen ? (
                <div
                    className={`max-w-screen flex h-[500px] flex-col rounded-md bg-white shadow-xl ${xs ? 'w-full' : 'w-[400px]'}`}>
                    <div className="mb-2 flex items-center justify-between rounded-t-md bg-[#4AA9B1] p-4 text-white">
                        <h2 className="text-lg font-semibold">Coach Aiah</h2>
                        <button onClick={toggleChat} className="text-sm">
                            Close
                        </button>
                    </div>
                    <div className="mb-2 flex-1 overflow-y-auto bg-white px-4 py-2">
                        {messages
                            ?.filter((item) => item.role !== 'system')
                            .map((msg) => (
                                <div
                                    key={`${msg.id}-${msg.role}`}
                                    className={`mb-2 flex ${msg.role === 'assistant' ? 'justify-start' : 'justify-end'}`}>
                                    <div className={`${msg.role === 'assistant' ? '' : 'text-right'}`}>
                                        <div className="text-sm text-gray-500">
                                            {msg.role === 'assistant' ? 'Coach Aiah' : 'You'}{' '}
                                            <span className="text-xs text-gray-400">
                                                <DateFormatComponent dateString={msg.createdAt} />
                                            </span>
                                        </div>
                                        <div
                                            className={`inline-block max-w-full rounded p-2 text-sm ${msg.role === 'assistant' ? 'bg-gray-200 text-left' : 'bg-[#4AA9B1] text-right text-white'}`}>
                                            {msg.content}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        {isTyping && (
                            <div className="mb-2 flex justify-start">
                                <div className="inline-block max-w-full rounded bg-gray-200 p-2 text-left text-sm">
                                    <span className="typing-dot text-3xl">.</span>
                                    <span className="typing-dot text-3xl">.</span>
                                    <span className="typing-dot text-3xl">.</span>
                                </div>
                            </div>
                        )}
                        <div ref={chatEndRef}></div> {/* Empty div for scrolling to bottom */}
                    </div>

                    <div className="px-4 py-4">
                        <div className="mb-1 flex gap-1">
                            <Tooltip title="Each learner is given a maximum of 10 message tokens for each lesson.">
                                <FaInfoCircle className="text-slate-400" />
                            </Tooltip>
                            <p className=" text-xs text-slate-400">You have {tokensLeft} tokens left</p>
                        </div>

                        <div className="flex">
                            <input
                                ref={inputRef}
                                type="text"
                                className="mr-2 flex-1 rounded border p-2"
                                placeholder="Type your message..."
                                value={message}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                disabled={isSendDisabled}
                            />
                            <button
                                className={`rounded  bg-[#4AA9B1] p-2 text-white ${isSendDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
                                onClick={handleSendMessage}
                                disabled={isSendDisabled}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <Tooltip
                    title={
                        currentLesson.status === 'NOT_STARTED'
                            ? "Current lesson must be started to access Coach Aiah's help."
                            : null
                    }>
                    <div
                        onClick={currentLesson.status === 'NOT_STARTED' ? null : toggleChat}
                        className={`flex h-16 w-16 cursor-pointer items-center justify-center rounded-full`}>
                        <img src={CoachAiah} alt="Chat Icon" className="h-full w-full rounded-full" />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

export default OpenAiChatbot;
