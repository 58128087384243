import React, { useContext, useEffect, useState } from 'react';
import { Layout, Tabs, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AuthProviderContext } from '../providers/AuthProvider';
import MainHeader from '../components/layout/Header';
import Background from '../components/layout/Background';
import { tabItems } from '../data/HomeTabItems';
import { UserProviderContextV2 } from '../providers/UserProviderV2';
const { Content } = Layout;

export const Dashboard = () => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;

    return (
        <Layout className="home-body">
            <Background orgId={currentUser.orgId} />
            <MainHeader />
            <Content
                style={{
                    minHeight: '100vh',
                    position: 'relative'
                }}>
                <div className="home-tabs">
                    <Tabs
                        defaultActiveKey="1"
                        items={tabItems}
                        style={{
                            padding: xs ? '20px 36px' : '20px 60px',
                            minHeight: '100vh',
                            maxWidth: '1920px',
                            marginInline: 'auto'
                        }}></Tabs>
                </div>
            </Content>
        </Layout>
    );
};

const HomePage = () => {
    const { authState } = useContext(AuthProviderContext);

    const [localLoader, setLocalLoader] = useState(true);
    const navigate = useNavigate();

    const userToken = localStorage.getItem('UserToken');

    useEffect(() => {
        if (!userToken) {
            setLocalLoader(false);
        }
    }, [authState, navigate]);

    return <Dashboard />;
};

export default HomePage;
