import { fetchApi } from './api/ApiGwOptions';

export const getPathwaysByOrgStatus = async (orgId) => {
    const getPathwaysByOrgStatusQuery = `
    query getPathwaysByOrgStatus($input: GetPathwaysByOrgStatusInput!) {
    getPathwaysByOrgStatus(input: $input) {
        id
        orgId
        status
        title
        description
        createdAt
        updatedAt
        coverPhotoUrl
        courses {
            id
            title
            description
            percent
            deps
            }
        }
    }
    `;

    const input = {
        // email: email,
        orgId: orgId
        // id: id
    };

    const params = {
        query: getPathwaysByOrgStatusQuery,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response?.data?.getPathwaysByOrgStatus;
};
