import { Grid, Statistic, Typography, Button } from 'antd';
import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

const DesktopCompleteButtonAndTimer = ({
    showRunningTime,
    showExpiredTime,
    showCompleteLessonButton,
    showLessonCompletedButton,
    computeDeadline,
    setForceSubmitPromptModalOpen,
    completeLessonIsLoading,
    onCompleteLessonClick,
    sessionType,
    assignmentInfo
}) => {
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const { Text } = Typography;
    const { Countdown } = Statistic;

    return (
        <div className={`${xs ? 'w-full flex-row justify-between' : 'w-[400px] justify-end'} flex items-center gap-4`}>
            {sessionType === 'Lab' && (
                <>
                    {showRunningTime && (
                        <div className={`${xs ? '' : 'gap-3'} flex w-full items-center`}>
                            <Text className="mr-1 text-gray-500">Time remaining:</Text>
                            <Countdown
                                value={computeDeadline()}
                                onFinish={() => {
                                    setForceSubmitPromptModalOpen(true);
                                }}
                            />
                        </div>
                    )}

                    {/* {showExpiredTime && (
                        <div className={`${xs ? '' : 'gap-3'} flex w-full items-center justify-end`}>
                            <Text className="mr-1 text-gray-500">Time remaining:</Text>
                            <Countdown value={new Date().setHours(0, 0, 0, 0)} />
                        </div>
                    )} */}

                    {/* IF SCREENSIZE IS MED/LARGE */}
                    {!xs && showCompleteLessonButton && (
                        <Button
                            className="bg-primary"
                            size="medium"
                            type="primary"
                            loading={completeLessonIsLoading}
                            onClick={() => {
                                onCompleteLessonClick();
                            }}>
                            Complete Lesson
                        </Button>
                    )}
                    {!xs && showLessonCompletedButton && (
                        <div className="flex  items-center justify-center gap-2">
                            <CheckCircleOutlined />
                            <p className="m-0 p-0 leading-3 ">
                                {showExpiredTime ? 'Force Completed' : 'Lesson Completed'}
                            </p>
                        </div>
                    )}
                </>
            )}
            {sessionType === 'Assignment' && (
                <>
                    {!xs && assignmentInfo?.status === 'IN_PROGRESS' && (
                        <Button
                            className="bg-primary"
                            size="medium"
                            type="primary"
                            loading={completeLessonIsLoading}
                            onClick={() => {
                                onCompleteLessonClick();
                            }}>
                            Complete Assignment
                        </Button>
                    )}
                    {!xs && assignmentInfo?.status === 'COMPLETED' && (
                        <div className="flex  items-center justify-center gap-2">
                            <CheckCircleOutlined />
                            <p className="m-0 p-0 leading-3 ">Assignment Completed</p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default DesktopCompleteButtonAndTimer;
