import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, Input, Button, Typography } from 'antd';
import { FcGoogle } from 'react-icons/fc';
import { showErrorToast } from '../utils/ToastError';
import { ToastContainer } from 'react-toastify';

// Providers
import { AuthProviderContext } from '../providers/AuthProvider';
import { UserProviderContext } from '../providers/UserProvider';

import Logo from '../assets/logo.png';
import PageLoader from '../components/layout/loaders/PageLoader';
import '../assets/css/signin.css';
import { API_GATEWAY_ENDPOINT } from '../queries/api/ApiGwOptions';

const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 }
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 }
};

const { Text } = Typography;

const isManagerBtnStyle = {
    position: 'fixed',
    top: 0,
    right: 20
};

const UserSignin = () => {
    const { authState, authDispatch } = useContext(AuthProviderContext);
    const { userDispatch } = useContext(UserProviderContext);
    const [isManager, setIsManager] = useState(false);
    const navigate = useNavigate();
    const managerToken = localStorage.getItem('PeopleMgrToken');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const toggleVisibility = () => {
        setIsManager(!isManager);
    };
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (searchParams?.get('isManager') === 'true') {
            setIsManager(true);
            localStorage.clear();
            return;
        }

        if (managerToken) {
            authDispatch({ type: 'SIGN_IN_AS_MANAGER' });
            navigate('/manager-console');
        }

        if (!managerToken) {
            const checkAuth = async () => {
                try {
                    let data = await Auth.currentAuthenticatedUser();
                    userDispatch({
                        type: 'SET_COGNITO_USER',
                        payload: {
                            username: data.username,
                            ...data.attributes
                        }
                    });

                    authDispatch({ type: 'SIGN_IN' });
                    navigate('/');
                } catch (error) {
                    // User is not authenticated, do nothing
                    authDispatch({ type: 'SIGN_OUT' });
                }
            };
            checkAuth();
        }
    }, [navigate, userDispatch, authDispatch]);

    const signInAsManager = (values) => {
        const REQUEST_URL = `${API_GATEWAY_ENDPOINT}/loginPeopleMgr?email=${values.email}&password=${values.password}`;
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        };

        const signIn = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(REQUEST_URL, options);
                const data = await response.json();

                if (data.id) {
                    localStorage.setItem('PeopleMgrToken', data.token);
                    authDispatch({ type: 'SIGN_IN_AS_MANAGER' });
                    navigate('/manager-console');
                    window.location.reload();
                }

                if (!data.id) {
                    showErrorToast({ message: 'Invalid email address or password.' });
                }
            } catch (error) {
                console.error('Error fetching data:', error.message);
            }
            setIsLoading(false);
        };

        signIn();
    };

    const onFinish = (values) => {
        signInAsManager(values);
    };

    const onFinishFailed = (errorInfo) => {
        return errorInfo;
    };

    return !authState.loading ? (
        <React.Fragment>
            <Button
                type="link"
                size="large"
                onClick={() => toggleVisibility()}
                style={isManagerBtnStyle}
                className="text-primary">
                {isManager ? `Back to Learner's Sign In` : `Go to Manager's Console`}
            </Button>
            <div className="login-page-container">
                <img src={Logo} alt="Logo" className="logo" />

                <Text type="secondary" style={{ marginBottom: '10px' }}>
                    Sign in to continue
                </Text>

                <Form
                    {...layout}
                    style={{ width: '345px' }}
                    name="login-form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}>
                    {isManager && (
                        <React.Fragment>
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input email address.' }]}>
                                <Input size="large" placeholder="Email" className="input-centered-round" />
                            </Form.Item>

                            <Form.Item name="password" rules={[{ required: true, message: 'Please input password' }]}>
                                <Input.Password size="large" placeholder="Password" className="input-centered-round" />
                            </Form.Item>
                        </React.Fragment>
                    )}

                    <Form.Item {...tailLayout}>
                        {isManager ? (
                            <Button
                                type="primary"
                                size="large"
                                htmlType="submit"
                                shape="round"
                                className="signin"
                                loading={isLoading}
                                // onClick={() => {
                                //     // authDispatch({ type: 'SIGN_IN_AS_MANAGER' });
                                //     // navigate('/manager-console');
                                // }}
                            >
                                Sign in
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                size="large"
                                shape="round"
                                className="google"
                                onClick={() => Auth.federatedSignIn({ provider: 'Google' })}>
                                <FcGoogle size={24} style={{ marginRight: '10px' }} className="text-white"></FcGoogle>
                                Continue with Google
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
            <ToastContainer />
        </React.Fragment>
    ) : (
        <PageLoader />
    );
};

export default UserSignin;
