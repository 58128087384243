import React, { createContext, useReducer, useEffect, useContext } from 'react';
import { API_GATEWAY_ENDPOINT, apiGatewayOptions } from '../queries/api/ApiGwOptions';
import axios from 'axios';
import { UserProviderContextV2 } from './UserProviderV2';

const initialState = {
    isAuthenticated: false,
    loading: true
};
export const AuthProviderContextV2 = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return { ...state, isAuthenticated: true, loading: false };
        case 'SIGN_OUT':
            return { ...state, isAuthenticated: false, loading: false };
        case 'LOADING':
            return { ...state, loading: true };
        case 'LOADING_FINISH':
            return { ...state, loading: false };
        default:
            return state;
    }
};

export const AuthProviderV2 = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { userDispatch } = useContext(UserProviderContextV2);
    const userToken = localStorage.getItem('UserToken');

    const getCurrentUser = async () => {
        try {
            const response = await axios.get(`${API_GATEWAY_ENDPOINT}/getUserV2`, apiGatewayOptions);

            if (response.data) {
                dispatch({ type: 'SIGN_IN' });
                userDispatch({ type: 'SET_CURRENT_USER', payload: response.data.currentUser });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                dispatch({ type: 'SIGN_OUT' });
                localStorage.removeItem('UserToken');
                window.location.href = '/signin?sessionExpired=true';
            } else {
                console.error('Error fetching user data:', error);
            }
        }
    };

    useEffect(() => {
        if (userToken) {
            getCurrentUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AuthProviderContextV2.Provider value={{ authState: state, authDispatch: dispatch }}>
            {children}
        </AuthProviderContextV2.Provider>
    );
};
