import { fetchApi } from './api/ApiGwOptions';

export const getUserAssignment = async (getAssignmentInput) => {
    const getUserAssignmentQuery = `
        query GetAssignment($input: GetAssignmentInput) {
            getAssignment(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
            }
        }
        `;
    const params = {
        query: getUserAssignmentQuery,
        variables: { input: getAssignmentInput }
    };

    const response = await fetchApi(params);

    return response?.data?.getAssignment;
};

export const createAssignment = async (createAssignmentInput) => {
    const createAssignmentMutation = `
        mutation CreateAssignment($input: CreateAssignmentInput) {
            createAssignment(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
            }
        }
        `;
    const params = {
        query: createAssignmentMutation,
        variables: { input: createAssignmentInput }
    };

    const response = await fetchApi(params);

    return response?.data?.createAssignment;
};

export const completeUserAssignment = async (completeAssignmentInput) => {
    const completeAssignmentMutation = `
        mutation CompleteAssignment($input: CompleteAssignmentInput) {
            completeAssignment(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
            }
        }
        `;
    const params = {
        query: completeAssignmentMutation,
        variables: { input: completeAssignmentInput }
    };

    const response = await fetchApi(params);

    return response.data.completeAssignment;
};

export const submitAssignmentResponse = async (submitAssignmentResponseData) => {
    const submitAssignmentResponseMutation = `
        mutation submitAssignmentResponse($input: SubmitAssignmentResponseInput) {
            submitAssignmentResponse(input: $input) {
                id
                title
                createdAt
                updatedAt
                timeStarted
                timeCompleted
                status
                submissions
        }
        }
        `;

    const params = {
        query: submitAssignmentResponseMutation,
        variables: { input: submitAssignmentResponseData }
    };

    const response = await fetchApi(params);

    return response?.data?.submitAssignmentResponse;
};

// SPRINGBOARD
export const getAssignmentContentFromSpringboard = async (contentId) => {
    const getAssignmentQuerySpringboard = `
        query GetAssignmentQuery($id: ID!) {
            assignment(id: $id) {
            id
            title
            contentMd
            description
            }
        }
        `;

    const params = {
        query: getAssignmentQuerySpringboard,
        variables: { id: contentId }
    };

    const response = await fetchApi(params, 'springboard');

    return response.data;
};
