import { Layout, Typography } from 'antd';
import Background from '../layout/Background';
import { UIConfig, orgId } from '../../config/ui-config';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const { Content } = Layout;
const { Text } = Typography;

const PageError = ({ message }) => {
    const loaderLogo = UIConfig[orgId]?.Loaders.WhiteLogo;
    const loaderStyles = UIConfig[orgId]?.Loaders.WhiteLogoStyles;

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/signin');
        }, 2000);
    }, []);

    return (
        <div className="absolute top-0 z-50">
            <div className="relative">
                <Background />
                <Content className="relative flex min-h-screen w-screen flex-col items-center justify-center">
                    <img src={loaderLogo} alt={'logo'} className={loaderStyles} />
                    <Text className="mt-4 text-lg text-white">{message}</Text>
                    <Text className="mt-4 text-lg text-white">Redirecting to sign in...</Text>
                </Content>
            </div>
        </div>
    );
};

export default PageError;
