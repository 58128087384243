import { useContext, useEffect, useState } from 'react';
import { Row, Space, Col, Grid } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import { getCoursesByUserPathway } from '../../queries/courses';
import { getPathwayTitle } from '../../utils/getPathwayTitle';
import CourseStatusLoader from '../layout/loaders/CourseStatusLoader';
import EmptyListIndicator from '../layout/EmptyListIndicator';

const CompletedContent = () => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [courses, setCourses] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    useEffect(() => {
        const getPathways = async () => {
            setIsLoading(true);

            //Get all courses, for display in the in progress section
            let _courses = await getCoursesByUserPathway('', currentUser.id, true, currentUser.orgId);
            setCourses(_courses);
            //---------------------------------------------------
            //---------------------------------------------------

            setIsLoading(false);
        };

        getPathways();
    }, []);

    useEffect(() => {
        setCompletedCourses(() => {
            return courses.filter((item) => item.percent === '100');
        });
    }, [courses]);

    return (
        <Space direction="vertical" className="mt-12 w-full">
            <Col>
                {isLoading ? (
                    <CourseStatusLoader />
                ) : (
                    <>
                        {completedCourses.length === 0 ? (
                            <EmptyListIndicator message={'You have not completed any courses yet.'} />
                        ) : (
                            <>
                                <h2 className="mb-4 text-xl font-semibold text-white ">You have completed</h2>
                                {completedCourses.map((item, index) => {
                                    return (
                                        <div className="bg-white" key={index}>
                                            <Row
                                                onClick={() => {
                                                    navigate(`/course/${item.id}`);
                                                }}
                                                style={{
                                                    background:
                                                        'linear-gradient(-270deg, rgba(250, 250, 250, 1), rgba(206, 157, 44, 0.25))'
                                                }}
                                                className="mb-4 cursor-pointer items-center justify-between p-4 ">
                                                <Row className="w-full gap-4">
                                                    <Row className={xs ? 'h-full w-full' : 'h-[100px] w-[100px]'}>
                                                        <img
                                                            alt="course-cover"
                                                            src={item.coverPhotoUrl}
                                                            className="h-full w-full object-cover"
                                                        />
                                                    </Row>
                                                    <Row
                                                        className={`items-center" flex flex-col justify-between ${
                                                            xs ? 'gap-2' : ''
                                                        } `}>
                                                        <h4 className="text-gray-500">
                                                            {getPathwayTitle(item.pathwayId, currentUser.orgId)}
                                                        </h4>
                                                        <h2 className="text-lg font-semibold">{item.title}</h2>
                                                        <p
                                                            className={`flex flex-row  ${
                                                                xs ? 'items-start' : 'items-center'
                                                            }`}>
                                                            <CheckCircleFilled
                                                                className={`mr-2 text-green-400 ${xs ? 'mt-1' : ''}`}
                                                            />
                                                            Great work! You have fulfilled all the requirements for this
                                                            course.
                                                        </p>
                                                    </Row>
                                                </Row>
                                            </Row>
                                        </div>
                                    );
                                })}
                            </>
                        )}
                    </>
                )}
            </Col>
        </Space>
    );
};

export default CompletedContent;
