import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, Input, Typography } from 'antd';
import { showErrorToast } from '../utils/ToastError';
import { ToastContainer } from 'react-toastify';
import { API_GATEWAY_ENDPOINT } from '../queries/api/ApiGwOptions';
import { registerApiGatewayOptions } from './Register';
import { AuthProviderContextV2 } from '../providers/AuthProviderV2';
import { UserProviderContextV2 } from '../providers/UserProviderV2';
import { trimErrorMessage } from '../utils/trimErrorMessage';
import { UIConfig, orgId } from '../config/ui-config';
import axios from 'axios';

const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 }
};

const tailLayout = {
    wrapperCol: { offset: 0, span: 24 }
};

const { Text } = Typography;

const UserV2Signin = () => {
    const [lockedUntil, setLockedUntil] = useState(localStorage.getItem('lockedUntil') || null);
    const [loginLocked, setLoginLocked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(null); // State for the countdown time
    const { authDispatch } = useContext(AuthProviderContextV2);
    const { userDispatch } = useContext(UserProviderContextV2);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isSessionExpired = searchParams?.get('sessionExpired');
    const navigate = useNavigate();
    const logoSrc = UIConfig[orgId]?.SignIn?.Logo;

    useEffect(() => {
        if (isSessionExpired) {
            showErrorToast({ message: 'Your session has expired. Please sign in again.' });
        }
    }, [isSessionExpired]);

    // Countdown timer logic
    useEffect(() => {
        if (lockedUntil) {
            const now = Date.now();
            const lockedUntilTime = parseInt(lockedUntil, 10);

            if (now < lockedUntilTime) {
                setLoginLocked(true);

                // Calculate the initial countdown
                const initialCountdown = Math.floor((lockedUntilTime - now) / 1000);
                setCountdown(initialCountdown);

                const interval = setInterval(() => {
                    const remainingTime = Math.floor((lockedUntilTime - Date.now()) / 1000);
                    setCountdown(remainingTime);

                    if (remainingTime <= 0) {
                        clearInterval(interval);
                        setLoginLocked(false);
                        localStorage.removeItem('lockedUntil');
                    }
                }, 1000);

                return () => clearInterval(interval); // Cleanup on unmount
            } else {
                setLoginLocked(false);
                localStorage.removeItem('lockedUntil');
            }
        }
    }, [lockedUntil]);

    const handleLogin = async (values) => {
        values.orgId = orgId;
        setIsLoading(true);

        try {
            const res = await axios.post(`${API_GATEWAY_ENDPOINT}/login`, values, registerApiGatewayOptions);
            localStorage.setItem('UserToken', res.data.token);

            authDispatch({ type: 'SIGN_IN' });
            userDispatch({ type: 'SET_CURRENT_USER', payload: res.data });
            setIsLoading(false);

            navigate('/');
        } catch (error) {
            if (error.response.status === 429) {
                localStorage.setItem('lockedUntil', error.response.data.lockedUntil);
                setLockedUntil(error.response.data.lockedUntil);
            }
            console.log('ERROR', error.response);
            showErrorToast({ message: trimErrorMessage(error.response.data.message) });
        }
        setIsLoading(false);
    };

    const onFinish = (values) => {
        handleLogin(values);
    };

    const onFinishFailed = (errorInfo) => {
        return errorInfo;
    };

    return (
        <div className="login-page-container">
            <img
                src={logoSrc}
                alt="Logo"
                className={`${UIConfig[orgId]?.SignIn?.LogoStyles} cursor-pointer`}
                onClick={() => {
                    navigate('/signin');
                }}
            />
            <Text type="secondary" className="mb-8 mt-4">
                Sign in to continue
            </Text>
            <Form
                {...layout}
                style={{ width: '345px' }}
                name="login-form"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}>
                <React.Fragment>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: 'Email address is required.' },
                            { type: 'email', message: 'Please enter a valid email address.' }
                        ]}>
                        <Input
                            size="large"
                            placeholder="Email"
                            className="input-centered-round"
                            disabled={loginLocked}
                        />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: 'Please input password' }]}>
                        <Input.Password
                            size="large"
                            placeholder="Password"
                            className="input-centered-round"
                            disabled={loginLocked}
                        />
                    </Form.Item>
                </React.Fragment>

                <Form.Item {...tailLayout}>
                    <button
                        className={`signin rounded-full  p-3 text-white ${loginLocked ? 'cursor-not-allowed' : ''}`}
                        disabled={loginLocked}
                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = UIConfig[orgId]?.SecondaryColor)}
                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = UIConfig[orgId]?.PrimaryColor)}>
                        {isLoading ? 'Signing in...' : 'Sign in'}
                    </button>

                    {loginLocked && (
                        <p
                            className=" mb-4 cursor-pointer text-center text-red-900"
                            onClick={() => {
                                navigate('/forgot-password');
                            }}>
                            You have reached the maximum allowed number of failed password attempts. Please try again in{' '}
                            {Math.floor(countdown / 60)} minutes and {countdown % 60} seconds.
                        </p>
                    )}

                    <p
                        className=" cursor-pointer text-center text-primary"
                        onClick={() => {
                            navigate('/forgot-password');
                        }}>
                        Forgot password?
                    </p>
                </Form.Item>
            </Form>

            <p>
                Don't have an account yet?{' '}
                <span
                    className="cursor-pointer font-bold text-primary"
                    onClick={() => {
                        navigate('/register');
                    }}>
                    Register
                </span>
            </p>

            <ToastContainer />
        </div>
    );
};

export default UserV2Signin;
