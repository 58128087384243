import React, { useContext, useEffect, useState } from 'react';
import { Row, Space } from 'antd';
import PathwayCard from '../pathway/PathwayCard';
import CarouselSwiper from '../layout/CarouselSwiper';
import PathwayLoader from '../pathway/PathwayLoader';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import { getPathwaysByOrgStatus } from '../../queries/pathways';
import { getCoursesByUserPathway } from '../../queries/courses';
import '../../assets/css/home.css';
import '../../assets/css/loader.css';

const MainContent = () => {
    const [pathways, setPathways] = useState([]);
    const [courses, setCourses] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;

    useEffect(() => {
        const getPathways = async () => {
            setIsLoading(true);

            //Get all pathways-----------------------------------
            // const _pathways = await getPathwaysByOrgStatus(currentUser.email, currentUser.orgId, currentUser.id);
            const _pathways = await getPathwaysByOrgStatus(currentUser.orgId);
            setPathways(_pathways);
            //---------------------------------------------------
            //---------------------------------------------------

            //Get all courses, for display in the in progress section
            let _courses = await getCoursesByUserPathway('', currentUser.id, true, currentUser.orgId);
            setCourses(_courses);
            //---------------------------------------------------
            //---------------------------------------------------

            setIsLoading(false);
        };

        getPathways();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    console.log('PATHWAYS', pathways);
    return (
        <Space direction="vertical" className="mt-12 w-full">
            <h2 className="mb-2 text-xl font-semibold text-white">My Pathways</h2>

            {isLoading ? (
                <PathwayLoader />
            ) : (
                <Row gutter={[16, 16]}>
                    {pathways
                        ?.filter((item) => currentUser.enrolledPathways.includes(item.id))
                        .map((pw, idx) => {
                            return (
                                <PathwayCard
                                    key={idx}
                                    image={pw.coverPhotoUrl}
                                    title={pw.title}
                                    disabled={!currentUser.enrolledPathways.includes(pw.id)}
                                    slug={pw.id}
                                />
                            );
                        })}
                </Row>
            )}

            {courses && (
                <div className="mb-16">
                    <h2 className="mb-4 mt-16 text-xl font-semibold text-white">My Progress</h2>

                    {isLoading ? (
                        <PathwayLoader />
                    ) : (
                        <CarouselSwiper
                            slides={courses.filter((item) => {
                                return item.status === 'STARTED' && item.percent !== '100';
                            })}
                            emptyMessage={'You have no on-going courses.'}
                        />
                    )}
                </div>
            )}
        </Space>
    );
};

export default MainContent;
