import React from 'react';
import { Typography, Grid, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';

const Breadcrumbs = ({ currentLessonIndicator, pathwayTitle, pathwaySlug }) => {
    const { Text } = Typography;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();

    const navigate = useNavigate();

    // const pathwayTitle = localStorage.getItem('pathway-title');
    // const pathwaySlug = localStorage.getItem('pathway-slug');

    const limitStringWithEllipsis = (inputString) => {
        const limit = 36;
        if (inputString?.length > limit) {
            return inputString?.slice(0, limit) + '...';
        } else {
            return inputString;
        }
    };

    let displayLessonName;

    if (xs) {
        displayLessonName = limitStringWithEllipsis(currentLessonIndicator?.lessonTitle?.split(':')[0]);
    } else {
        displayLessonName = currentLessonIndicator?.lessonTitle?.split(':')[0];
    }

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        } else {
            return str;
        }
    };

    return (
        <>
            {currentLessonIndicator.module ? (
                <div className={`flex ${xs ? 'flex-col' : ''} `}>
                    {!xs && (
                        <>
                            <Tooltip title={pathwayTitle}>
                                <Text
                                    className=" text-gray-500"
                                    // onClick={() => {
                                    //     // navigate(`/pathway/${pathwaySlug}?t=${pathwayTitle}`);
                                    //     navigate(`/pathway/${pathwaySlug}`);
                                    // }}
                                >
                                    {truncateString(pathwayTitle, 25)} /
                                </Text>
                            </Tooltip>
                        </>
                    )}
                    <Tooltip title={currentLessonIndicator?.module}>
                        <Text className={`${!xs ? 'mx-1' : ''} text-gray-500`}>
                            {truncateString(currentLessonIndicator?.module, 25)} /
                        </Text>
                    </Tooltip>
                    <Tooltip title={displayLessonName}>
                        <Text>{truncateString(displayLessonName, 25)} </Text>
                    </Tooltip>
                </div>
            ) : (
                <p className="custom-loader" style={{ width: '350px', height: '18px', opacity: '0.2' }}></p>
            )}
        </>
    );
};

export default Breadcrumbs;
