import { useContext, useEffect, useState } from 'react';
import { Button, Row, Typography, Grid } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { showErrorToast } from '../../utils/ToastError';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import {
    fetchCourseModules,
    getCoursesByUserPathway,
    getCurrentCourse,
    startCurrentCourse
} from '../../queries/courses';
import { getCurrentLessonByUser } from '../../queries/lessons';
import { isEnabled } from '../../utils/isEnabled';
import { flattenedSessionItemsToLesson } from '../../utils/flattenedSessionItemsToLesson';
import PageLoader from '../layout/loaders/PageLoader';
import CarouselSwiper from '../layout/CarouselSwiper';
import '../../assets/css/home.css';
import 'react-toastify/dist/ReactToastify.css';

const CourseIntro = () => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [courses, setCourses] = useState([]);
    const [enableStartCourse, setEnableStartCourse] = useState(false);
    const [courseIsStarting, setCourseIsStarting] = useState(false);
    const [modules, setModules] = useState([]);
    const [lessons, setLessons] = useState([]);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [firstLessonId, setFirstLessonId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const { Title } = Typography;
    const { slug } = useParams();
    const navigate = useNavigate();
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const [pathwayId, setPathwayId] = useState(null);
    const location = useLocation();

    useEffect(() => {
        //Get current lesson. Used for displaying the active lesson when redirected to Course Content page
        const fetchCurrentLessonByUser = async () => {
            let _currentLesson = await getCurrentLessonByUser(currentUser.id, slug);
            if (_currentLesson) {
                setCurrentLesson(_currentLesson);
                navigate(`/course/${slug}/cc/${_currentLesson.id}`);
            }
        };
        //---------------------------------------------------
        //---------------------------------------------------

        //Get courses, for display on the other courses section
        const fetchCourse = async () => {
            const _currentCourse = await getCurrentCourse(currentUser.id, slug);

            if (!_currentCourse) {
                navigate('/');
                return;
            }
            setPathwayId(_currentCourse.pathwayId);
        };
        //---------------------------------------------------
        //---------------------------------------------------

        fetchCurrentLessonByUser();
        fetchCourse();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const getCourseDetails = async () => {
            setIsLoading(true);

            //Get courses, for display on the other courses section
            let _courses = await getCoursesByUserPathway(pathwayId, currentUser.id, true, currentUser.orgId);

            if (!_courses) {
                navigate('/');
                return;
            }

            setCourses(
                _courses?.map((crs) => {
                    return {
                        ...crs,
                        disabled: !isEnabled(parseFloat(crs.percent), crs.deps)
                    };
                })
            );
            //---------------------------------------------------
            //---------------------------------------------------

            //Get current course's modules from springboard
            const getCourseModules = await fetchCourseModules(slug);
            setModules(getCourseModules.cycle);
            //---------------------------------------------------
            //---------------------------------------------------

            //Sorts modules then sets the firstLessonId
            const firstModule = getCourseModules.cycle.sessions[0]?.sessionItems;
            firstModule?.sort((a, b) => {
                let labelA = a.title.toLowerCase().split(':')[0];
                let labelB = b.title.toLowerCase().split(':')[0];

                if (labelA < labelB) {
                    return -1;
                }
                if (labelA > labelB) {
                    return 1;
                }
                return 0;
            });

            setFirstLessonId(flattenedSessionItemsToLesson(getCourseModules?.cycle?.sessions)[0]?.id);

            setLessons(flattenedSessionItemsToLesson(getCourseModules.cycle.sessions));
            if (getCourseModules.cycle.sessions.length > 0) {
                setEnableStartCourse(true);
            }
            //---------------------------------------------------
            //---------------------------------------------------

            setIsLoading(false);
        };

        if (!currentLesson && pathwayId) {
            getCourseDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLesson, location, pathwayId]);

    const startCourse = async () => {
        if (lessons.length <= 0) {
            showErrorToast({ message: 'No lessons available for this course yet. Redirecting to home.' });
            setTimeout(() => {
                navigate('/');
            }, 3000);
        }

        if (!currentLesson) {
            setCourseIsStarting(true);
            //Starts current course
            await startCurrentCourse(
                JSON.stringify(lessons),
                slug,
                firstLessonId,
                currentUser.id,
                currentUser.orgId,
                pathwayId
            );
            //---------------------------------------------------
            //---------------------------------------------------
            setCourseIsStarting(false);
            navigate(`/course/${slug}/cc/${firstLessonId}`);
        } else {
            navigate(`/course/${slug}/cc/${currentLesson.id}`);
        }
    };

    return (
        <>
            {isLoading ? (
                <PageLoader />
            ) : (
                <Row className={`${xs ? 'p-6' : 'p-12'} mx-auto flex min-h-screen w-screen max-w-[1920px] flex-col`}>
                    <div className="bg-circle" style={{ opacity: '0.05' }}></div>
                    <div className="globe-vector" style={{ opacity: '0.1' }}></div>
                    <Title>{modules?.title}</Title>
                    <div className="text-lg">
                        {modules?.description !== ''
                            ? modules?.description?.split('\n\n').map((par, index) => (
                                  <p className="mb-4" key={index}>
                                      {par}
                                  </p>
                              ))
                            : modules?.title}
                    </div>
                    <Row className="mt-8 gap-4">
                        {enableStartCourse && (
                            <Button type="primary" loading={courseIsStarting} onClick={() => startCourse()}>
                                Start course
                            </Button>
                        )}
                        <Button
                            onClick={() => {
                                navigate(`/`);
                            }}>
                            Go back to home
                        </Button>
                    </Row>

                    <Row className="mt-24 w-[100%]">
                        <Row className="flex w-full items-center justify-between">
                            <h4 className="mb-2 self-start text-lg font-semibold text-primary">Other Courses</h4>
                        </Row>
                        {courses.length !== 0 && (
                            <div className=" flex w-full">
                                <CarouselSwiper slides={courses?.filter((item) => item.id !== slug)} bgPrimary={true} />
                            </div>
                        )}
                    </Row>
                </Row>
            )}
            <ToastContainer />
        </>
    );
};

export default CourseIntro;
