import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthProviderContextV2 } from '../../providers/AuthProviderV2';

const ProtectedRoute = ({ children }) => {
    const { authState } = useContext(AuthProviderContextV2);
    const navigate = useNavigate();

    const userToken = localStorage.getItem('UserToken');

    useEffect(() => {
        if (!userToken) {
            navigate('/signin');
        }
    }, []);

    return authState?.isAuthenticated ? children : null;
};

export default ProtectedRoute;
