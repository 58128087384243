import React, { createContext, useReducer } from 'react';

const initialState = { currentUser: null, loading: true };
export const UserProviderContextV2 = createContext(initialState);

const reducer = (state, action) => {
    switch (action.type) {
        // case 'SET_COGNITO_USER':
        //     return { ...state, cognitoUser: action.payload, loading: false };
        case 'SET_CURRENT_USER':
            return { ...state, currentUser: action.payload, loading: false };
        case 'LOADING':
            return { ...state, loading: true };
        default:
            return state;
    }
};

export const UserProviderV2 = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <UserProviderContextV2.Provider value={{ userState: state, userDispatch: dispatch }}>
            {children}
        </UserProviderContextV2.Provider>
    );
};
