import { useContext, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Button } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import CourseLoader from '../../layout/loaders/CourseLoader';
import { createAssignment, getUserAssignment } from '../../../queries/assignments';
import { UserProviderContextV2 } from '../../../providers/UserProviderV2';
import MarkdownWrapper from '../../layout/MarkdownWrapper';

const AssignmentContent = ({ assignmentInfo, assignmentContent, setAssignmentInfo, setAssignmentContent }) => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [loading, setLoading] = useState(false);

    const { contentId, slug } = useParams();
    const navigate = useNavigate();

    const getAssignmentInput = {
        userId: currentUser.id,
        assignmentId: contentId,
        courseId: slug
    };

    const createAssignmentInput = {
        userId: currentUser.id,
        assignmentId: contentId,
        courseId: slug,
        title: assignmentContent?.title
    };

    const startAssignment = async () => {
        setLoading(true);

        const newAssignment = await createAssignment(createAssignmentInput);

        const _assignmentInfo = await getUserAssignment(getAssignmentInput);
        setAssignmentInfo(_assignmentInfo);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    return (
        <>
            {loading ? (
                <CourseLoader />
            ) : (
                <>
                    <h1 className="mb-5 text-5xl font-semibold">{assignmentContent?.title}</h1>
                    {!assignmentInfo ? (
                        <div className="h-[300px]">
                            <h3 className="my-5 text-2xl font-semibold">{assignmentContent?.description}</h3>
                            <div className={`mb-5 flex  gap-2 `}>
                                <Button
                                    style={{}}
                                    size="large"
                                    onClick={() => {
                                        navigate(`/`);
                                    }}>
                                    Go back to home
                                </Button>
                                <Button
                                    className="bg-primary text-white"
                                    size="large"
                                    loading={loading}
                                    onClick={() => {
                                        startAssignment();
                                    }}>
                                    Start Assignment
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <MarkdownWrapper>
                            <ReactMarkdown>{assignmentContent?.contentMd}</ReactMarkdown>
                        </MarkdownWrapper>
                    )}
                </>
            )}
        </>
    );
};

export default AssignmentContent;
