import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';

const Sidebar = ({ modules, menuItems, isMenuOpen, setIsMenuOpen, contentId }) => {
    const navigate = useNavigate();
    const scrollIntoViewRef = useRef(null);
    const { slug } = useParams();
    const [openKeys, setOpenKeys] = useState([]);

    const handleMenuItemClick = (key) => {
        navigate(`/course/${slug}/cc/${key}`);
        scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });

        if (isMenuOpen) {
            setIsMenuOpen(false);
        }
    };

    const toggleSubMenu = (key) => {
        setOpenKeys((prevOpenKeys) =>
            prevOpenKeys.includes(key) ? prevOpenKeys.filter((k) => k !== key) : [...prevOpenKeys, key]
        );
    };

    useEffect(() => {
        const findOpenKey = (items) => {
            for (const item of items) {
                if (
                    item.key === contentId ||
                    (item.children && item.children.some((child) => child.key === contentId))
                ) {
                    return item.key;
                }
                if (item.children) {
                    const childKey = findOpenKey(item.children);
                    if (childKey) return item.key;
                }
            }
            return null;
        };

        const openKey = findOpenKey(menuItems);
        if (openKey) {
            setOpenKeys([openKey]);
        }
    }, [contentId, menuItems]);

    return (
        <div className="border-r-2 border-gray-100 bg-white">
            <div className="px-6 py-3 pr-10 font-semibold">{modules?.title}</div>
            <ul className="w-auto overflow-y-auto">
                {menuItems.map((item) => (
                    <li key={item.key}>
                        <div
                            className={`flex cursor-pointer items-start justify-between gap-2 py-2 pl-6 pr-2 ${openKeys.includes(item.key) ? 'font-semibold text-primary' : ''}`}
                            onClick={() => (item.children ? toggleSubMenu(item.key) : handleMenuItemClick(item.key))}>
                            <div className="flex gap-2">
                                <span>{item.icon}</span>
                                <span>{item.label}</span>
                            </div>
                            {item.children && (
                                <span className="mt-1">
                                    {openKeys.includes(item.key) ? (
                                        <MdOutlineKeyboardArrowUp />
                                    ) : (
                                        <MdOutlineKeyboardArrowDown />
                                    )}
                                </span>
                            )}
                        </div>
                        {item.children && openKeys.includes(item.key) && (
                            <ul className="pl-4">
                                {item.children.map((child, index) => (
                                    <Tooltip
                                        key={child.key}
                                        title={
                                            item.disabled || child.disabled
                                                ? `Lesson will be available on ${dayjs(child.disabled ? child.schedule : item.schedule).format('MMMM DD, h:mm A ')}`
                                                : null
                                        }>
                                        <li
                                            className={`flex  gap-2 py-2 pl-8 pr-4  ${contentId === child.key ? 'bg-gray-200 font-semibold text-primary' : ''} ${child.disabled || item.disabled ? 'cursor-not-allowed text-gray-500' : 'cursor-pointer'}`}
                                            onClick={() =>
                                                child.disabled || item.disabled ? null : handleMenuItemClick(child.key)
                                            }>
                                            <span>{child.icon}</span>
                                            <span>{child.label}</span>
                                        </li>
                                    </Tooltip>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Sidebar;
