import { useContext, useEffect, useRef, useState } from 'react';
import { Layout, Grid } from 'antd';
import dayjs from 'dayjs';
import {
    UnlockOutlined,
    VideoCameraOutlined,
    FileMarkdownOutlined,
    LinkOutlined,
    OrderedListOutlined,
    CarryOutOutlined,
    LockOutlined
} from '@ant-design/icons';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { showSuccessToast } from '../../utils/ToastSuccess';
import { showErrorToast } from '../../utils/ToastError';
import { fetchCourseLessons, fetchCourseModules, getCurrentCourse } from '../../queries/courses';
import { completeUserLesson, getCurrentLessonByUser, getLesson, submitLabResponse } from '../../queries/lessons';
import { UserProviderContextV2 } from '../../providers/UserProviderV2';
import {
    completeUserAssignment,
    getAssignmentContentFromSpringboard,
    getUserAssignment,
    submitAssignmentResponse
} from '../../queries/assignments';
import CourseLoader from '../layout/loaders/CourseLoader';
import QuizContent from './contentTypes/QuizContent';
import AssignmentContent from './contentTypes/AssignmentContent';
import Breadcrumbs from './courseComponents/Breadcrumbs';
import PageLoader from '../layout/loaders/PageLoader';
import VideoContent from './contentTypes/VideoContent';
import MarkdownContent from './contentTypes/MarkdownContent';
import UrlContent from './contentTypes/UrlContent';
import MobileMenu from './courseComponents/sidebar/MobileMenu';
import DesktopMenu from './courseComponents/sidebar/DesktopMenu';
import LabResponseModal from './courseComponents/LabResponseModal';
import CourseTabs from './courseComponents/CourseTabs';
import DesktopCompleteButtonAndTimer from './courseComponents/DesktopCompleteButtonAndTimer';
import MobileCompleteButtonAndTimer from './courseComponents/MobileCompleteButtonAndTimer';
import OpenAiChatbot from './courseComponents/OpenAiChatbot';
import '../../assets/css/course.css';
import 'react-toastify/dist/ReactToastify.css';

const CoursePage = () => {
    const { userState } = useContext(UserProviderContextV2);
    const { currentUser } = userState;
    const [modules, setModules] = useState([]);
    // const [courses, setCourses] = useState([]);
    const [currentCourse, setCurrentCourse] = useState(null);
    const [lessonStartTime, setLessonStartTime] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const [loadingLesson, setLoadingLesson] = useState(false);
    const [currentLessonIndicator, setCurrentLessonIndicator] = useState(null);
    const [currentLesson, setCurrentLesson] = useState(null);
    const [exerciseDetails, setExerciseDetails] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [completeLessonModalOpen, setcompleteLessonModalOpen] = useState(false);
    const [completeLessonIsLoading, setCompleteLessonIsLoading] = useState(false);
    const [submitLabResponsePromptModalOpen, setSubmitLabResponsePromptModalOpen] = useState(false);
    const [holdTextAreaValue, setHoldTextAreaValue] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasTimerExpired, setHasTimerExpired] = useState(false);
    const [forceSubmitPromptModalOpen, setForceSubmitPromptModalOpen] = useState(false);
    const [sessionType, setSessionType] = useState(null);

    const [assignmentInfo, setAssignmentInfo] = useState(null);
    const [assignmentContent, setAssignmentContent] = useState(null);

    // console.log('Current Course', currentCourse);
    // CHATBOT STATES
    const [isChatOpen, setIsChatOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);

    const scrollIntoViewRef = useRef(null);
    const { Content } = Layout;
    const { useBreakpoint } = Grid;
    const { xs } = useBreakpoint();
    const { slug, contentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const defaultLabResponse = `{}`;

    const [pathwayId, setPathwayId] = useState(null);

    const environment = process.env.REACT_APP_ENVIRONMENT;
    // console.log('ENV', environment);

    const getCurrentUserCourse = async () => {
        const _currentCourse = await getCurrentCourse(currentUser.id, slug);

        if (!_currentCourse) {
            navigate('/');
            return;
        }
        setCurrentCourse(_currentCourse);
        setPathwayId(_currentCourse.pathwayId);

        return _currentCourse;
    };

    useEffect(() => {
        const fetchLesson = async (_course) => {
            const getCourseModules = await fetchCourseModules(slug);
            setModules(getCourseModules.cycle);
            const { lab: lesson } = await fetchCourseLessons(contentId);

            const fetchResponse = await getCurrentLessonByUser(currentUser?.id, slug, lesson?.id);

            let {
                status = null,
                substatus = null,
                submissions = null,
                timeStarted = null,
                insert = false
            } = fetchResponse || {};

            if (!fetchResponse) {
                status = 'NOT_STARTED';
                substatus = 'IS_NOT_CURRENT';
                insert = true;
            }

            setCurrentLesson({
                ...lesson,
                status,
                substatus,
                submissions,
                timeStarted,
                insert,
                courseId: slug
            });

            setLessonStartTime(timeStarted);

            let currentModule = getCourseModules.cycle.sessions.find((item) =>
                item.sessionItems.some((child) => child.id === contentId)
            );
            let currentLessonIndex = currentModule.sessionItems.findIndex((element) => element.id === contentId);

            let initialSessionType = currentModule.sessionItems[currentLessonIndex].__typename;
            setSessionType(initialSessionType);

            setCurrentLessonIndicator({
                ...currentLessonIndicator,
                module: currentModule.title,
                lesson: `Lesson ${currentLessonIndex + 1}`,
                lessonTitle: currentModule.sessionItems[currentLessonIndex].title
            });

            if (lesson?.exerFileContent) {
                let tempExerciseDetails = JSON.parse(lesson?.exerFileContent);
                setExerciseDetails({
                    title: tempExerciseDetails.title,
                    link: tempExerciseDetails.link
                });
            }
        };

        const fetchData = async () => {
            const currentCourse = await getCurrentUserCourse();
            if (currentCourse) {
                fetchLesson(currentCourse);
            }
        };

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentCourse) {
            // console.log('FROM LOCATION EFFECT', currentCourse);

            const currentLesson = currentCourse?.schedule
                ?.flatMap((item) => item.sessionItems)
                ?.find((sessionItem) => sessionItem.sessionItemId === contentId)?.schedule;

            const now = new Date(dayjs().format());
            const currentLessonSched = new Date(dayjs(currentLesson).format());

            const isSessionLocked = now < currentLessonSched;

            if (isSessionLocked) {
                navigate('/');
            }
        }

        let currentModule = menuItems?.find((item) => item.children.some((child) => child.key === contentId));
        let currentLessonIndex = currentModule?.children?.findIndex((element) => element.key === contentId);
        let currentSessionType = currentModule?.children[currentLessonIndex].__typename;

        //Chatbox states reset
        setMessages([]);
        setMessage('');
        setIsChatOpen(false);

        setSessionType(currentSessionType);

        const fetchLesson = async () => {
            setLoadingLesson(true);
            const { lab: lesson } = await getLesson(contentId);

            // GET USER LESSONS DATA FOR "SPECIFIC LESSON" on TLP BACKEND HERE BEFORE SETTING LESSON
            const fetchResponse = await getCurrentLessonByUser(currentUser?.id, slug, lesson?.id);

            let {
                status = null,
                substatus = null,
                submissions = null,
                timeStarted = null,
                insert = false
            } = fetchResponse || {};

            if (!fetchResponse) {
                status = 'NOT_STARTED';
                substatus = 'IS_NOT_CURRENT';
                insert = true;
            }

            if (lesson.exerFileContent) {
                let tempExerciseDetails = JSON.parse(lesson.exerFileContent);
                setExerciseDetails({
                    title: tempExerciseDetails.title,
                    link: tempExerciseDetails.link
                });
            }

            setCurrentLesson({
                ...lesson,
                status,
                substatus,
                submissions,
                timeStarted,
                insert,
                courseId: slug
            });

            setLessonStartTime(timeStarted);
            setLoadingLesson(false);
        };

        const fetchAssignment = async () => {
            const getAssignmentInput = {
                userId: currentUser.id,
                assignmentId: contentId,
                courseId: slug
            };

            setLoadingLesson(true);
            const _assignmentContent = await getAssignmentContentFromSpringboard(contentId);
            setAssignmentContent(_assignmentContent?.assignment);
            const _assignmentInfo = await getUserAssignment(getAssignmentInput);
            setAssignmentInfo(_assignmentInfo);
            setLoadingLesson(false);
        };

        // Checks if currentLesson already exist on
        // the first useEffect to avoid race condition
        if (currentLesson) {
            if (currentSessionType === 'Lab') {
                fetchLesson();
            }
            if (currentSessionType === 'Assignment') {
                fetchAssignment();
            }
        }
        setCurrentLessonIndicator({
            ...currentLessonIndicator,
            module: currentModule?.label,
            lesson: `Lesson ${currentLessonIndex + 1}`,
            lessonTitle: currentModule?.children[currentLessonIndex]?.label
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, sessionType]);

    // console.log('CURRENT COURSE', currentCourse);
    useEffect(() => {
        if (modules?.sessions && currentCourse) {
            const sched = currentCourse?.schedule;

            const now = new Date(dayjs().format());

            const tempArray = modules.sessions.flatMap((session, i) => {
                let lessonIndex = 0;

                const sessionSched = new Date(
                    dayjs(sched?.filter((item) => item.id === session.id)[0]?.schedule)?.format()
                );

                const isSessionLocked = now < sessionSched;

                session?.sessionItems?.sort((a, b) => {
                    let labelA = a.title.toLowerCase().split(':')[0];
                    let labelB = b.title.toLowerCase().split(':')[0];

                    if (labelA < labelB) {
                        return -1;
                    }
                    if (labelA > labelB) {
                        return 1;
                    }
                    return 0;
                });

                const children = session?.sessionItems?.map((item) => {
                    const icon = item.__typename === 'Lab' ? item.contentType : item.__typename;
                    lessonIndex++;

                    const lessonHasSchedule = sched
                        ?.filter((mod) => mod.id === session.id)[0]
                        ?.sessionItems?.filter((child) => child.sessionItemId === item.id)[0]?.schedule
                        ? sched
                              ?.filter((mod) => mod.id === session.id)[0]
                              ?.sessionItems?.filter((child) => child.sessionItemId === item.id)[0]?.schedule
                        : null;

                    const lessonSched = lessonHasSchedule ? new Date(dayjs(lessonHasSchedule).format()) : null;

                    const isLessonLocked = now < lessonSched;

                    return {
                        ...getItem(
                            item.title,
                            item.id,
                            getContentTypeIcon(icon),
                            isLessonLocked,
                            lessonSched ? lessonSched : sessionSched
                        ),
                        lessonindex: lessonIndex,
                        lessontype: item.contentType || 'Markdown', // Handle the case when contentType is not available
                        __typename: item.__typename
                    };
                });

                return getItem(
                    session.title,
                    session.id,
                    isSessionLocked ? <LockOutlined /> : <UnlockOutlined />,
                    isSessionLocked,
                    sessionSched,
                    children
                );
            });

            setMenuItems(tempArray);
            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modules, currentCourse]);

    useEffect(() => {
        // const isLocked = currentCourse?.schedules?.some((item) =>
        //     item.sessionItems.some((sessionItem) => sessionItem.id === contentId)
        // );

        // console.log('IS LOCKED', isLocked);
        if (currentLesson && sessionType === 'Lab') {
            try {
                checkTimerExpiresOnInit();
            } catch (error) {
                console.log(`Error fetching other courses on course content.`);
                throw Error(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLesson]);

    function getContentTypeIcon(item) {
        switch (item) {
            case 'Markdown':
                return <FileMarkdownOutlined />;
            case 'Video':
                return <VideoCameraOutlined />;
            case 'URL':
                return <LinkOutlined />;
            case 'Quiz':
                return <OrderedListOutlined />;
            case 'Assignment':
                return <CarryOutOutlined />;
            default:
                return <FileMarkdownOutlined />;
        }
    }

    // getItem is a default function from ant design
    function getItem(label, key, icon, disabled, schedule, children, type) {
        return {
            key,
            icon,
            children,
            label,
            disabled,
            schedule,
            type
        };
    }

    const handleLabResponseSubmit = async ({ textareaValue, timerExpired }) => {
        const submitLabResponseData = {
            userId: currentUser.id,
            courseId: currentLesson.courseId,
            lessonId: currentLesson.id,
            submissions: textareaValue
        };

        const submitAssignmentResponseData = {
            userId: currentUser.id,
            courseId: slug,
            assignmentId: contentId,
            submissions: textareaValue
        };

        setIsSubmitting(true);

        let submitResponseResult;

        if (sessionType === 'Lab') {
            submitResponseResult = await submitLabResponse(submitLabResponseData);
        }

        if (sessionType === 'Assignment') {
            submitResponseResult = await submitAssignmentResponse(submitAssignmentResponseData);
            setAssignmentInfo(submitResponseResult);
        }

        if (!timerExpired) {
            setCurrentLesson({
                ...currentLesson,
                submissions: textareaValue
            });
        }

        setIsSubmitting(false);

        if (textareaValue !== '{}') {
            if (submitResponseResult) {
                showSuccessToast({
                    message: `${sessionType === 'Assignment' ? 'Assignment' : 'Lab'} Response Submitted`
                });
            } else {
                showErrorToast({
                    message: `${sessionType === 'Assignment' ? 'Assignment' : 'Lab'} Response Submission Failed`
                });
            }
        }
    };

    const handleTimerExpires = async () => {
        setCompleteLessonIsLoading(true);
        setHasTimerExpired(true);

        //explicitly declare timerExpired since setting setHasTimerExpired has a delay and
        //thus it is not immediately recognized as true when handleLabResponseSubmit function below is called
        if (!currentLesson.submissions) {
            await handleLabResponseSubmit({ textareaValue: defaultLabResponse, timerExpired: true });
        }

        await handleComplete();

        setCompleteLessonIsLoading(false);
        setForceSubmitPromptModalOpen(false);
        scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const checkTimerExpiresOnInit = async () => {
        const timerRemaining = computeDeadline();
        if (
            currentLesson?.contentType === 'Markdown' &&
            timerRemaining <= 0 &&
            currentLesson.status === 'IN_PROGRESS'
        ) {
            setForceSubmitPromptModalOpen(true);
        }
    };

    const handleCloseSubmitLabResponseModal = () => {
        let element = document.querySelector('.scroll-into-lab-response');
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth'
            });
        } else {
            return null;
        }
        setSubmitLabResponsePromptModalOpen(false);
    };

    const handleComplete = async () => {
        const completeLessonInput = {
            uid: currentUser.id,
            courseId: slug,
            lessonId: contentId,
            orgId: currentUser.orgId,
            pathwayId: pathwayId
        };

        const completeLesson = async () => {
            setCompleteLessonIsLoading(true);
            await completeUserLesson(completeLessonInput);

            setLessonStartTime(null);

            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
            setHoldTextAreaValue('');
            setCurrentLesson((prev) => ({ ...prev, status: 'COMPLETED' }));
            setCompleteLessonIsLoading(false);
        };

        const completeAssignmentInput = {
            uid: currentUser.id,
            courseId: slug,
            assignmentId: contentId,
            pathwayId: pathwayId
        };

        const completeAssignment = async () => {
            setCompleteLessonIsLoading(true);
            const _assignmentInfo = await completeUserAssignment(completeAssignmentInput);
            setAssignmentInfo(_assignmentInfo);
            scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
            setCompleteLessonIsLoading(false);
        };

        if (sessionType === 'Lab') {
            completeLesson();
        }

        if (sessionType === 'Assignment') {
            completeAssignment();
        }
    };

    const onCompleteLessonClick = async () => {
        if (sessionType === 'Assignment') {
            if (!assignmentInfo?.submissions) {
                setSubmitLabResponsePromptModalOpen(true);
                return;
            } else {
                setcompleteLessonModalOpen(true);
                return;
            }
        }

        if (currentLesson.contentType === 'Markdown' || currentLesson.contentType === null) {
            if (currentLesson.submissions) {
                setcompleteLessonModalOpen(true);
            } else {
                let textAreaContent = document.querySelector('.labResponseTextArea');
                setHoldTextAreaValue(textAreaContent?.innerHTML);
                setSubmitLabResponsePromptModalOpen(true);
            }
        }

        if (currentLesson.contentType !== null && currentLesson.contentType !== 'Markdown') {
            setCompleteLessonIsLoading(true);
            if (currentLesson.submissions === null) {
                await handleLabResponseSubmit({ textareaValue: defaultLabResponse });
            }
            await handleComplete();
        }
    };

    const computeDeadline = () => {
        let startTime = lessonStartTime || Date.now();
        let quizDuration = currentLesson?.durationMins * 60000;

        const nowDiff = Date.now() - startTime;
        const remaining = quizDuration - nowDiff;

        if (remaining <= 0) {
            return 0;
        }

        return Date.now() + remaining;
    };

    // TIMER AND COMPLETE BUTTON DISPLAY CONDITIONS
    const showRunningTime =
        currentLesson?.status !== 'COMPLETED' &&
        lessonStartTime &&
        !hasTimerExpired &&
        (currentLesson?.contentType === 'Markdown' || currentLesson?.contentType === null);

    const showExpiredTime =
        (currentLesson?.contentType === 'Markdown' &&
            currentLesson?.status === 'COMPLETED' &&
            currentLesson?.submissions === '{}') ||
        hasTimerExpired;

    const showCompleteLessonButton = currentLesson?.status === 'IN_PROGRESS' && lessonStartTime;

    const showLessonCompletedButton = currentLesson?.status === 'COMPLETED';

    return (
        <>
            {modules.length === 0 ? (
                <PageLoader />
            ) : (
                <Layout className="relative overflow-x-hidden bg-white">
                    {/* MOBILE MENU */}
                    {isMenuOpen && (
                        <MobileMenu
                            modules={modules}
                            menuItems={menuItems}
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            contentId={contentId}
                        />
                    )}
                    <Layout className="course-layout">
                        {/* DESKTOP MENU */}
                        <DesktopMenu
                            modules={modules}
                            menuItems={menuItems}
                            isMenuOpen={isMenuOpen}
                            setIsMenuOpen={setIsMenuOpen}
                            contentId={contentId}
                            xs={xs}
                            // schedules={currentCourse?.schedule}
                        />

                        {loadingLesson ? (
                            <CourseLoader />
                        ) : (
                            // COURSE CONTENT
                            <Layout className="course-content">
                                <div ref={scrollIntoViewRef}></div>

                                <div
                                    className={`${
                                        xs ? 'flex-col' : 'flex-row'
                                    } sticky top-0 z-10 flex items-center justify-between bg-white px-6 py-3`}>
                                    <div className={`${xs ? 'w-full' : ''} flex h-[40px] items-center justify-between`}>
                                        {/* BREADCRUMBS */}
                                        <Breadcrumbs
                                            currentLessonIndicator={currentLessonIndicator}
                                            pathwayTitle={currentCourse?.title}
                                            pathwaySlug={currentCourse?.pathwayId}
                                        />

                                        {/* MOBILE TIMER AND COMPLETE BUTTON */}
                                        {xs && (
                                            <MobileCompleteButtonAndTimer
                                                currentLesson={currentLesson}
                                                completeLessonModalOpen={completeLessonModalOpen}
                                                onCompleteLessonClick={onCompleteLessonClick}
                                                setcompleteLessonModalOpen={setcompleteLessonModalOpen}
                                                setIsMenuOpen={setIsMenuOpen}
                                                isMenuOpen={isMenuOpen}
                                                sessionType={sessionType}
                                                assignmentInfo={assignmentInfo}
                                            />
                                        )}
                                    </div>

                                    {/* DESKTOP TIMER AND COMPLETE BUTTON */}
                                    {(sessionType === 'Lab' || sessionType === 'Assignment') && (
                                        <DesktopCompleteButtonAndTimer
                                            showRunningTime={showRunningTime}
                                            showExpiredTime={showExpiredTime}
                                            showCompleteLessonButton={showCompleteLessonButton}
                                            showLessonCompletedButton={showLessonCompletedButton}
                                            computeDeadline={computeDeadline}
                                            setForceSubmitPromptModalOpen={setForceSubmitPromptModalOpen}
                                            completeLessonIsLoading={completeLessonIsLoading}
                                            onCompleteLessonClick={onCompleteLessonClick}
                                            sessionType={sessionType}
                                            assignmentInfo={assignmentInfo}
                                            currentLesson={currentLesson}
                                            lessonStartTime={lessonStartTime}
                                        />
                                    )}
                                </div>
                                {/* <pre>{JSON.stringify(currentCourse.schedule, null, 2)}</pre> */}
                                <Content className={`${isMenuOpen ? 'no-scroll' : ''} m-0 mt-6 min-h-[280px] px-6`}>
                                    {!sessionType ? (
                                        <CourseLoader />
                                    ) : (
                                        // LESSONS DISPLAY
                                        <>
                                            {sessionType === 'Lab' && (
                                                <>
                                                    {(currentLesson?.contentType === 'Markdown' ||
                                                        currentLesson?.contentType === null) && (
                                                        <MarkdownContent
                                                            lessonStartTime={lessonStartTime}
                                                            setLessonStartTime={setLessonStartTime}
                                                            currentLesson={currentLesson}
                                                            setCurrentLesson={setCurrentLesson}
                                                            pathwayId={pathwayId}
                                                        />
                                                    )}

                                                    {currentLesson?.contentType === 'Video' && (
                                                        <VideoContent
                                                            currentLesson={currentLesson}
                                                            setCurrentLesson={setCurrentLesson}
                                                            lessonStartTime={lessonStartTime}
                                                            setLessonStartTime={setLessonStartTime}
                                                            pathwayId={pathwayId}
                                                        />
                                                    )}

                                                    {currentLesson?.contentType === 'URL' && (
                                                        <UrlContent
                                                            currentLesson={currentLesson}
                                                            setCurrentLesson={setCurrentLesson}
                                                            lessonStartTime={lessonStartTime}
                                                            setLessonStartTime={setLessonStartTime}
                                                            pathwayId={pathwayId}
                                                        />
                                                    )}
                                                </>
                                            )}
                                            {sessionType === 'Quiz' && (
                                                <QuizContent courseId={slug} pathwayId={pathwayId} />
                                            )}
                                            {sessionType === 'Assignment' && (
                                                <AssignmentContent
                                                    assignmentInfo={assignmentInfo}
                                                    assignmentContent={assignmentContent}
                                                    setAssignmentInfo={setAssignmentInfo}
                                                    setAssignmentContent={setAssignmentContent}
                                                />
                                            )}
                                        </>
                                    )}

                                    {/* SUBMIT LAB RESPONSE SCROLL REFERENCE */}
                                    <div
                                        className="scroll-into-lab-response "
                                        style={{ height: '100px', width: '100%' }}></div>

                                    {/* TABS AND OTHER COURSES */}
                                    <CourseTabs
                                        sessionType={sessionType}
                                        currentLesson={currentLesson}
                                        holdTextAreaValue={holdTextAreaValue}
                                        hasTimerExpired={hasTimerExpired}
                                        isSubmitting={isSubmitting}
                                        handleLabResponseSubmit={handleLabResponseSubmit}
                                        exerciseDetails={exerciseDetails}
                                        // courses={courses}
                                        assignmentInfo={assignmentInfo}
                                    />
                                </Content>

                                {/* TOAST AND MODALS */}
                                <ToastContainer />
                                <LabResponseModal
                                    completeLessonModalOpen={completeLessonModalOpen}
                                    handleComplete={handleComplete}
                                    setcompleteLessonModalOpen={setcompleteLessonModalOpen}
                                    submitLabResponsePromptModalOpen={submitLabResponsePromptModalOpen}
                                    setSubmitLabResponsePromptModalOpen={setSubmitLabResponsePromptModalOpen}
                                    handleCloseSubmitLabResponseModal={handleCloseSubmitLabResponseModal}
                                    forceSubmitPromptModalOpen={forceSubmitPromptModalOpen}
                                    completeLessonIsLoading={completeLessonIsLoading}
                                    handleTimerExpires={handleTimerExpires}
                                    navigate={navigate}
                                    sessionType={sessionType}
                                />
                            </Layout>
                        )}
                    </Layout>
                    {/* CHATBOX */}
                    {currentLesson && sessionType === 'Lab' && environment !== 'prod' && (
                        <OpenAiChatbot
                            isChatOpen={isChatOpen}
                            setIsChatOpen={setIsChatOpen}
                            currentUser={currentUser}
                            currentLesson={currentLesson}
                            message={message}
                            setMessage={setMessage}
                            messages={messages}
                            setMessages={setMessages}
                            location={location}
                        />
                    )}
                </Layout>
            )}
        </>
    );
};

export default CoursePage;
