import { fetchApi } from './api/ApiGwOptions';

export const getAllMessages = async (userId, lessonId, courseId, email, lessonTitle) => {
    const getAllMessagesQuery = `
    query getAllMessages($input: GetAllMessagesInput!) {
        getAllMessages(input: $input) {
            allMessages {
                createdAt
                role
                content
                id
            }
            userMessageCount
        }
    }
`;

    const input = {
        userId: userId,
        lessonId: lessonId,
        courseId: courseId,
        email: email,
        lessonTitle: lessonTitle
    };

    const params = {
        query: getAllMessagesQuery,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data.getAllMessages;
};

export const sendNewMessage = async (userId, lessonId, courseId, content, role, email, messages) => {
    const sendNewMessageMutation = `
    mutation sendNewMessage($input: SendNewMessageInput!) {
            sendNewMessage(input: $input) {
                id
                createdAt
                role
                content
                isDeleted
            }
        }
    `;

    const input = {
        userId: userId,
        lessonId: lessonId,
        courseId: courseId,
        content: content,
        role: role,
        email: email,
        messages: messages
    };

    const params = {
        query: sendNewMessageMutation,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data.sendNewMessage;
};
