import { Layout, Typography } from 'antd';
import Background from '../Background';
import { UIConfig, orgId } from '../../../config/ui-config';

const { Content } = Layout;
const { Text } = Typography;

const PageLoader = ({ message }) => {
    if (message) {
        return <div>{message}</div>;
    }

    const LoaderLogo = UIConfig[orgId]?.Loaders.WhiteLogo;
    const loaderStyles = UIConfig[orgId]?.Loaders.WhiteLogoStyles;

    return (
        <>
            <img src={LoaderLogo} alt={'logo'} />

            <div className="fixed top-0 z-50">
                <div className="relative">
                    <Background orgId={orgId} />
                    <Content className="relative flex min-h-screen w-screen flex-col items-center justify-center">
                        <img src={LoaderLogo} alt={'logo'} className={loaderStyles} />
                        <Text className="mt-4 text-lg text-white">App is loading...</Text>
                    </Content>
                </div>
            </div>
        </>
    );
};

export default PageLoader;
