import React, { useEffect, useRef } from 'react';
import { Layout } from 'antd';
import CoursePageHeader from '../components/layout/CoursePageHeader';
import '../assets/css/course.css';
import { Outlet } from 'react-router-dom';

const CoursePage = () => {
    useEffect(() => {
        scrollIntoViewRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);
    const scrollIntoViewRef = useRef(null);
    return (
        <>
            <Layout className="relative overflow-x-hidden bg-white">
                <div ref={scrollIntoViewRef}></div>
                <CoursePageHeader hideWelcome={true} primaryBg={true} />
                <Outlet></Outlet>
            </Layout>
        </>
    );
};

export default CoursePage;
