// jsm;
import React from 'react';
import { createRoot } from 'react-dom/client';

// css;
import 'antd/dist/reset.css';
import './assets/css/index.css';
import '../src/index.css';

import updateAmplifyConfigure from './utils/CustomAmplifyConfigure';

// react components;
import App from './App';

import { Amplify } from 'aws-amplify';

import { message } from 'antd';

const originSignin = window.location.href;

if (originSignin.includes('error_description=PostConfirmation')) {
    message.success('Successfully linked/registered your Google Account, Please re-login', 20);
}

Amplify.configure(updateAmplifyConfigure());

const title = process.env.REACT_APP_TITLE || 'CloudSchool PH';
const faviconPath = `${process.env.REACT_APP_FAVICON}` || '%PUBLIC_URL%/favicon.ico';

document.getElementById('app-title').innerText = title;
document.getElementById('favicon').setAttribute('href', faviconPath);

createRoot(document.getElementById('root')).render(<App />);
