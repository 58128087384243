import { fetchApi } from './api/ApiGwOptions';

export const getCurrentLessonByUser = async (userId, courseId, lessonId) => {
    const getCurrentLessonQuery = `
        query getCurrentLesson($input: GetCurrentLessonInput!) {
        getCurrentLesson(input: $input) {
            id
            userId
            courseId
            title
            timeStarted
            status
            substatus
            submissions
            createdAt
            updatedAt
        }
    }
    `;

    const input = {
        userId: userId,
        courseId: courseId,
        lessonId: lessonId ? lessonId : null
    };

    const params = {
        query: getCurrentLessonQuery,
        variables: { input }
    };

    const response = await fetchApi(params);
    return response.data?.getCurrentLesson;
};

export const getLesson = async (contentId) => {
    const getLessonQuery = `
        query lab($id: ID!) {
            lab(id: $id) {
                id
                title
                description
                bodyMd
                contentType
                videoContent
                urlContent
                exerFileContent
                durationMins
            }
        }
        `;

    const params = {
        query: getLessonQuery,
        variables: { id: contentId }
    };

    const response = await fetchApi(params, 'springboard');

    return response.data;
};

export const createUserLesson = async (createLessonInput) => {
    const createUserLessonQuery = `
        mutation createLesson($input: CreateLessonInput) {
            createLesson(input: $input) {
                id
                userId
                courseId
                title
                timeStarted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }
        `;

    const params = {
        query: createUserLessonQuery,
        variables: { input: createLessonInput }
    };

    const response = await fetchApi(params);

    return response.data;
};

export const startUserLesson = async (startUserLessonInput) => {
    const startUserLessonQuery = `
        mutation startUserLesson($input: StartLessonInput) {
            startUserLesson(input: $input) {
                id
                userId
                courseId
                title
                timeStarted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }
        `;

    const params = {
        query: startUserLessonQuery,
        variables: { input: startUserLessonInput }
    };

    const response = await fetchApi(params);

    return response.data?.startUserLesson;
};

export const completeUserLesson = async (completeLessonInput) => {
    const completeUserLessonQuery = `
        mutation completeUserLesson($input: CompleteLessonInput) {
            completeUserLesson(input: $input) {
                id
                userId
                courseId
                title
                timeStarted
                timeCompleted
                status
                substatus
                submissions
                createdAt
                updatedAt
            }
        }`;

    const params = {
        query: completeUserLessonQuery,
        variables: { input: completeLessonInput }
    };

    const response = await fetchApi(params);

    return response.data;
};

export const submitLabResponse = async (submitLabResponseData) => {
    const submitLabResponseMutation = `
        mutation submitLabResponse($input: SubmitLabResponseInput) {
            submitLabResponse(input: $input) {
                id
                userId
                courseId
                title
                submissions
                createdAt
                updatedAt
            }
        }
        `;

    const params = {
        query: submitLabResponseMutation,
        variables: { input: submitLabResponseData }
    };

    const response = await fetchApi(params);

    return response.data;
};
