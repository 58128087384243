import React from 'react';
import '../../assets/css/home.css';

const Background = ({ orgId }) => {
    const baseUrl = process.env.REACT_APP_HOSTNAME;

    return baseUrl.includes('gcashtechacademy') ? (
        <div>
            <div className="fixed-bg"></div>
            <div className="grain-texture"></div>
            <div className="globe-vector"></div>
            <div className="dark-gradient"></div>
            <div className="bg-circle"></div>
            <div className="yellow-gradient"></div>
        </div>
    ) : (
        <div>
            <div className="fixed-bg"></div>
            {orgId !== 'APPER-2024' && <div className="grain-texture"></div>}
        </div>
    );
};

export default Background;
